import React from "react";

//SVG Files for Clothing. 
//@params {fill}: rgb(r,g,b) color of the clothing

const height = "128pt";
const width = "183pt";


export const TShirt2 = ({fill}) => {
        return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="200.000000pt" height="200.000000pt" viewBox="0 0 1280.000000 1220.000000"
                preserveAspectRatio="xMidYMid meet">
                <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
                </metadata>
                <g transform="translate(0.000000,1220.000000) scale(0.100000,-0.100000)" fill={fill} stroke="black">
                <path d="M7756 12134 c-166 -160 -461 -310 -732 -375 -199 -47 -480 -63 -702
                -38 -331 35 -706 155 -1071 341 -96 48 -179 88 -184 88 -6 0 -135 -48 -286
                -106 -962 -369 -1421 -545 -1753 -673 l-376 -146 -1326 -1519 -1325 -1520 37
                -43 c20 -24 444 -500 942 -1057 l905 -1015 136 0 136 -1 239 252 239 251 5
                -3194 5 -3194 33 -85 c18 -47 37 -89 41 -93 4 -4 1650 -3 3657 4 l3648 12 108
                75 108 76 0 290 c0 160 -5 707 -10 1216 -8 835 -15 1509 -39 4030 -4 432 -6
                787 -5 789 2 2 123 -113 269 -255 147 -143 272 -260 278 -261 7 -2 58 7 115
                20 l103 22 924 1035 924 1035 1 155 0 155 -1219 1325 -1219 1325 -928 419
                c-511 231 -1081 489 -1267 573 -186 84 -339 153 -340 153 -1 -1 -33 -30 -71
                -66z"/>
                </g>
            </svg>
        )
}

export const TShirt = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="-20 -35 1280 1380"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M4425 12777 c-33 -13 -100 -45 -150 -71 -379 -205 -660 -325 -1163
            -497 -373 -127 -591 -189 -982 -279 -288 -67 -505 -172 -631 -306 -96 -103
            -206 -267 -284 -424 -74 -146 -225 -631 -225 -720 0 -46 -33 -122 -85 -197
            -57 -80 -108 -180 -125 -243 -7 -25 -18 -91 -25 -147 -11 -93 -16 -106 -45
            -140 -74 -83 -112 -218 -125 -443 -14 -224 -31 -299 -160 -715 -208 -666 -337
            -1170 -379 -1470 -23 -170 -40 -450 -41 -665 0 -177 2 -198 20 -229 14 -24 33
            -38 60 -47 22 -7 101 -34 175 -60 494 -172 676 -225 830 -243 47 -6 171 -15
            275 -21 553 -30 550 -30 628 -94 23 -19 75 -50 115 -69 40 -20 79 -41 87 -47
            31 -25 13 -119 -73 -390 -68 -214 -77 -270 -76 -495 0 -192 16 -350 44 -445
            18 -62 11 -117 -35 -259 -112 -349 -113 -554 -5 -821 23 -58 55 -138 71 -177
            48 -120 48 -167 0 -310 -67 -202 -76 -331 -33 -468 25 -76 39 -266 52 -720 13
            -415 25 -626 45 -738 27 -155 83 -358 110 -399 49 -73 47 -73 525 -73 550 -1
            623 -10 1165 -155 339 -91 577 -141 775 -164 74 -9 172 -21 216 -27 120 -17
            1068 -5 1274 16 501 50 805 136 1575 445 578 232 843 312 1155 350 140 17 267
            39 295 52 28 13 47 56 66 153 21 108 18 684 -4 880 -22 184 -49 322 -82 414
            -14 37 -25 85 -25 105 0 22 29 111 70 218 122 312 150 436 150 668 0 179 -9
            228 -60 346 -52 118 -52 173 -5 435 53 291 63 427 71 987 l7 502 119 0 c216 0
            330 38 475 159 45 37 105 78 135 89 29 12 139 40 244 61 246 51 330 72 543
            133 452 128 734 261 757 354 8 30 -36 259 -87 454 -85 324 -342 1165 -504
            1650 -125 372 -124 370 -122 595 1 218 -9 268 -83 385 -78 124 -99 179 -115
            296 -55 390 -154 823 -271 1173 -111 335 -254 515 -545 690 -209 124 -451 224
            -1146 469 -750 265 -940 346 -1193 509 -160 103 -181 113 -229 113 -21 0 -102
            -22 -180 -50 -335 -118 -694 -175 -1181 -187 -584 -14 -913 50 -1419 276 -62
            28 -127 51 -145 50 -17 0 -58 -10 -91 -22z m831 -1125 c-7 -7 -43 17 -50 33
            -4 11 3 9 24 -7 16 -12 28 -23 26 -26z m5153 -972 c0 -9 -4 -8 -9 5 -5 11 -9
            27 -9 35 0 9 4 8 9 -5 5 -11 9 -27 9 -35z m-8190 -7067 c-1 -85 -4 -128 -9
            -113 -13 41 -8 250 6 250 2 0 4 -62 3 -137z m7016 -393 c-4 -39 -9 -69 -12
            -67 -2 3 -1 37 3 76 3 40 9 70 11 67 3 -3 2 -37 -2 -76z m-18 -112 c-3 -7 -5
            -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-70 -1660 c-2 -24 -4 -5 -4 42 0
            47 2 66 4 43 2 -24 2 -62 0 -85z"/>
            </g>
        </svg>
    )
}

export const Jacket = ({fill}) => {
    return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={width} height={height} viewBox="50 0 1280 1080"
        preserveAspectRatio="xMidYMid meet">
        <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
        </metadata>
        <g transform="translate(0.000000,998.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
        <path d="M7415 9974 c-11 -2 -45 -9 -75 -15 -69 -14 -127 -45 -375 -203 -343
        -218 -348 -223 -401 -361 -14 -38 -42 -94 -60 -122 -47 -72 -162 -186 -290
        -284 -106 -82 -138 -118 -149 -171 -4 -15 -10 -30 -15 -33 -5 -3 -111 -37
        -237 -75 -290 -89 -418 -136 -569 -210 -246 -119 -464 -288 -728 -561 -344
        -355 -602 -673 -927 -1143 -79 -114 -211 -290 -293 -390 -203 -247 -238 -303
        -272 -423 -2 -7 -31 16 -66 51 -35 35 -79 71 -98 82 -52 27 -145 24 -260 -10
        -168 -49 -219 -32 -423 147 -171 149 -260 185 -432 174 -173 -11 -252 -51
        -332 -169 -24 -35 -41 -49 -70 -57 -121 -34 -282 18 -444 141 -106 81 -119 88
        -164 88 -59 0 -140 -51 -306 -193 -441 -377 -504 -485 -359 -610 42 -36 68
        -48 319 -146 85 -34 184 -80 219 -103 68 -44 142 -127 142 -158 0 -45 61 -252
        93 -315 53 -105 418 -543 511 -613 136 -104 214 -130 418 -138 139 -5 158 -4
        212 15 112 40 138 34 336 -80 224 -128 466 -249 555 -276 82 -26 155 -38 325
        -53 153 -14 209 -29 323 -87 153 -78 231 -72 395 30 48 30 132 78 187 107 55
        29 117 66 139 83 83 66 141 165 181 309 19 70 38 82 124 80 83 -2 110 17 75
        52 -11 11 -35 16 -84 16 l-68 0 21 56 c19 53 20 60 6 127 -8 40 -20 72 -28 75
        -10 4 -11 -4 -5 -39 22 -112 2 -196 -62 -264 -20 -22 -36 -53 -44 -87 -22 -92
        -75 -194 -128 -246 -29 -28 -90 -69 -153 -101 -57 -30 -144 -79 -193 -109
        -101 -63 -164 -85 -215 -76 -20 3 -76 26 -126 50 -134 65 -163 73 -334 89
        -162 16 -257 30 -294 44 -39 15 201 66 310 66 50 0 95 -4 99 -8 5 -4 3 -17 -4
        -27 -16 -25 -15 -30 3 -30 19 0 38 36 30 60 -16 50 -170 48 -375 -5 -52 -14
        -108 -25 -124 -25 -25 0 -27 3 -22 25 9 35 -15 85 -59 124 -47 41 -136 82
        -385 176 -255 97 -280 107 -280 114 0 3 15 13 33 22 33 16 66 68 67 106 0 30
        -26 53 -61 53 -38 0 -173 -45 -205 -68 -33 -24 -30 -48 21 -126 25 -39 42 -73
        38 -77 -5 -3 -31 -14 -59 -23 -44 -14 -68 -15 -192 -6 -78 6 -161 18 -184 26
        -42 14 -178 91 -178 99 0 16 37 95 56 122 13 17 57 52 97 77 58 36 83 46 116
        46 27 0 53 8 71 20 42 30 176 255 195 325 18 71 19 88 3 83 -7 -3 -19 -34 -29
        -70 -11 -44 -38 -100 -84 -174 -91 -146 -95 -151 -159 -156 -42 -3 -70 -15
        -130 -52 -82 -51 -115 -87 -142 -154 l-17 -43 -35 33 c-45 43 -363 424 -402
        482 -41 60 -74 150 -101 276 -12 58 -32 121 -44 140 -28 45 -100 111 -153 141
        -23 13 -42 26 -42 30 0 3 18 6 40 7 55 4 81 15 145 62 73 54 94 99 151 322 61
        238 62 252 30 317 -15 28 -24 51 -21 51 3 0 29 -13 58 -29 80 -45 178 -71 267
        -71 124 0 162 20 227 117 56 85 153 118 323 111 84 -3 105 -8 161 -35 37 -19
        102 -66 154 -114 151 -137 258 -199 344 -199 21 0 85 13 142 30 115 33 176 37
        218 15 16 -8 74 -62 129 -121 l100 -106 26 18 c19 12 25 22 20 33 -14 30 -16
        100 -4 135 18 56 84 150 220 316 156 191 215 270 360 480 297 431 608 807 955
        1155 316 316 555 467 950 603 143 49 458 143 463 138 11 -11 3 -94 -11 -123
        -24 -46 -44 -136 -66 -296 -20 -146 -40 -229 -86 -357 -59 -167 -79 -205 -163
        -308 -105 -130 -121 -158 -129 -221 -10 -85 -1 -89 171 -83 l144 5 4 -111 c5
        -168 -42 -152 474 -162 505 -11 662 -27 942 -101 174 -45 252 -82 287 -135 14
        -22 13 -28 -23 -101 -71 -141 -129 -355 -147 -538 -4 -38 -11 -74 -15 -80 -15
        -19 -61 -178 -85 -298 -45 -222 -52 -403 -23 -544 10 -50 28 -67 43 -43 19 31
        25 -4 25 -150 0 -89 7 -223 16 -298 20 -170 14 -644 -10 -817 -41 -296 -61
        -788 -52 -1310 8 -501 -4 -798 -43 -1090 -44 -327 -64 -570 -58 -715 8 -220
        -21 -656 -47 -699 -4 -5 -22 -15 -42 -21 -40 -14 -88 -5 -339 62 -342 91 -585
        171 -880 291 -213 86 -373 138 -545 178 -168 38 -239 88 -281 195 -19 51 -23
        82 -27 235 l-4 176 32 6 c37 8 58 -2 171 -79 169 -115 265 -150 445 -164 109
        -8 128 -12 224 -54 143 -61 211 -77 312 -74 108 4 99 21 -12 24 -101 3 -147
        15 -300 79 -107 44 -124 48 -235 56 -177 12 -240 37 -440 170 -97 64 -115 73
        -152 71 -26 -1 -45 4 -50 11 -4 7 -35 24 -68 37 -388 153 -480 257 -527 590
        -9 67 -13 217 -13 505 1 511 10 576 140 1005 62 205 112 413 131 549 27 196 7
        332 -88 587 -73 197 -131 291 -278 449 -159 172 -600 544 -688 582 -52 22 -70
        9 -107 -72 -18 -40 -39 -75 -47 -78 -30 -11 -83 27 -207 148 -132 128 -163
        146 -177 103 -6 -19 11 -39 110 -135 64 -62 140 -129 169 -148 93 -63 156 -42
        205 67 l27 61 70 -53 c272 -205 537 -443 662 -594 101 -122 146 -201 203 -360
        79 -221 85 -251 84 -421 -1 -175 -21 -281 -111 -590 -139 -478 -150 -533 -164
        -870 -15 -350 -1 -698 37 -879 8 -39 30 -103 50 -142 73 -145 183 -235 409
        -330 l115 -49 0 -122 c0 -294 51 -423 197 -500 34 -18 119 -46 204 -67 187
        -45 316 -89 537 -180 298 -123 785 -273 1102 -341 107 -22 109 -22 126 -4 34
        37 182 20 205 -24 11 -20 16 -20 238 -14 417 13 676 58 1086 192 77 25 194 57
        260 71 340 72 441 102 605 181 109 53 121 61 245 183 71 71 160 154 197 187
        79 69 171 169 288 313 139 171 191 250 276 419 108 216 135 305 139 444 2 87
        6 112 20 127 26 29 37 25 68 -25 38 -60 100 -90 170 -82 80 8 155 41 273 119
        122 80 297 247 356 338 19 30 119 216 221 413 103 196 205 385 228 420 23 34
        83 105 133 157 106 110 122 141 237 435 127 329 156 388 279 572 117 174 117
        176 56 372 -57 182 -86 320 -86 414 0 96 -16 198 -42 277 -11 36 -121 263
        -243 505 -123 242 -296 593 -385 780 -246 514 -315 650 -395 770 -186 279
        -544 652 -742 771 -86 51 -242 113 -553 218 -157 53 -332 115 -390 138 -228
        89 -417 202 -490 293 -19 23 -84 90 -144 146 -60 57 -191 194 -291 304 -100
        110 -202 218 -226 241 -107 97 -276 188 -417 223 -50 13 -132 22 -232 26 -85
        3 -240 13 -345 20 -181 14 -316 17 -360 9z m345 -74 c58 -5 200 -14 315 -20
        233 -11 286 -21 412 -80 154 -72 218 -126 431 -359 107 -119 257 -277 333
        -351 148 -145 215 -236 267 -363 34 -82 119 -370 212 -727 34 -129 89 -318
        122 -420 93 -289 91 -310 -30 -310 -93 0 -377 42 -622 91 -288 58 -330 54
        -440 -46 -19 -18 -73 -59 -120 -92 -47 -34 -148 -112 -225 -173 -155 -124
        -230 -166 -314 -175 -76 -8 -91 -16 -198 -97 -76 -58 -93 -76 -93 -97 0 -15
        -4 -31 -9 -36 -16 -17 -60 -130 -86 -222 -40 -137 -53 -242 -75 -608 -30 -487
        -36 -832 -19 -1002 22 -218 17 -696 -9 -903 -42 -331 -53 -620 -56 -1480 -3
        -637 -4 -683 -25 -815 -52 -339 -62 -459 -71 -870 -6 -255 -15 -439 -24 -508
        -22 -157 -16 -147 -79 -126 -28 10 -69 18 -89 19 -21 0 -38 1 -38 3 0 1 7 52
        16 112 10 73 18 244 24 505 9 407 21 565 71 885 21 133 22 176 25 845 3 756
        18 1159 54 1440 30 234 37 611 16 820 -11 107 -16 224 -14 335 l3 169 63 36
        c56 31 62 38 58 60 -46 208 -45 465 1 670 9 41 16 94 15 117 -4 70 18 215 34
        227 8 6 19 38 24 70 13 83 25 116 86 239 36 71 60 107 71 107 9 0 57 31 107
        68 112 85 118 88 186 95 90 10 179 64 447 270 348 267 320 250 411 255 62 3
        118 -4 282 -36 425 -84 656 -109 668 -73 4 11 -2 13 -28 8 -39 -8 -311 30
        -561 78 -101 20 -227 41 -280 47 -155 19 -138 28 -509 -257 -295 -226 -335
        -251 -418 -261 -80 -10 -108 -23 -203 -94 -51 -39 -94 -68 -96 -66 -7 7 212
        435 263 514 37 57 97 124 186 210 198 193 307 335 421 552 144 272 193 598
        127 843 -23 86 -36 114 -96 202 -50 73 -68 89 -119 111 -47 19 -245 33 -245
        17 0 -12 56 -23 121 -23 66 0 141 -25 170 -57 52 -56 90 -122 81 -139 -6 -10
        0 -44 16 -97 33 -112 42 -322 18 -450 -33 -174 -98 -333 -207 -509 -88 -143
        -161 -231 -320 -388 -181 -179 -220 -235 -341 -479 -46 -93 -86 -170 -89 -170
        -4 -1 -14 19 -23 44 -30 78 -114 195 -194 269 -162 151 -192 187 -310 364
        -209 316 -301 535 -364 867 -20 105 -23 153 -23 380 0 143 4 290 8 326 l8 66
        122 48 c204 79 287 138 337 235 40 78 15 84 -28 6 -53 -93 -117 -136 -335
        -222 -59 -23 -116 -40 -126 -37 -31 8 -39 -22 -52 -203 -34 -485 47 -894 253
        -1269 27 -49 101 -167 164 -262 103 -154 127 -183 240 -290 70 -65 143 -143
        164 -173 40 -59 94 -160 85 -160 -3 0 -25 15 -49 34 -96 73 -369 148 -697 191
        -149 20 -565 37 -797 33 l-183 -3 7 130 c6 116 5 133 -10 148 -14 14 -21 15
        -39 5 -27 -14 -247 -24 -247 -11 0 5 30 45 66 89 99 118 129 168 175 289 72
        191 93 275 124 503 9 62 27 145 42 185 17 48 29 109 35 181 5 60 14 119 21
        131 7 13 68 68 137 123 204 163 275 247 335 392 42 101 90 162 162 208 27 16
        147 93 268 169 194 123 263 159 340 176 38 9 271 7 375 -3z m1901 -1144 c135
        -70 245 -114 575 -226 501 -171 576 -205 725 -329 191 -158 453 -448 589 -651
        84 -126 117 -191 360 -700 122 -256 288 -595 370 -755 206 -403 249 -491 276
        -563 27 -70 28 -76 15 -67 -22 13 -21 -18 0 -42 20 -20 25 -37 27 -102 l4 -78
        -109 -31 c-59 -17 -198 -54 -308 -82 -408 -104 -609 -183 -580 -230 5 -8 14
        -5 29 8 59 54 229 112 641 217 143 37 275 73 292 80 31 13 33 13 37 -8 27
        -155 52 -264 86 -368 22 -70 40 -143 40 -162 0 -28 -19 -65 -92 -174 -107
        -162 -178 -306 -278 -568 -110 -289 -127 -321 -227 -422 -48 -49 -110 -124
        -139 -168 -44 -69 -165 -293 -323 -602 -43 -84 -58 -103 -47 -59 3 13 15 127
        26 252 11 126 24 256 30 288 5 32 7 63 4 68 -21 33 -37 -60 -64 -378 -11 -126
        -27 -257 -36 -289 -15 -61 -46 -105 -72 -105 -7 0 -12 -6 -9 -12 2 -7 10 -12
        16 -11 27 7 4 -24 -91 -120 -113 -113 -277 -230 -372 -266 -74 -27 -135 -27
        -170 0 -23 18 -56 67 -56 84 0 6 41 0 84 -12 17 -5 17 -4 -2 23 -13 19 -23 61
        -31 123 -10 83 -10 100 5 142 l18 49 -37 74 c-21 41 -44 79 -51 86 -8 6 -53
        18 -100 27 -122 22 -136 38 -136 153 0 102 -19 140 -70 140 -35 0 -93 -48
        -120 -99 l-19 -36 -55 118 c-31 64 -56 121 -56 127 0 16 -20 22 -26 7 -7 -19
        108 -262 128 -269 20 -8 30 0 57 44 22 37 75 88 91 88 5 0 16 -7 24 -16 12
        -12 16 -37 16 -105 0 -87 1 -90 33 -124 27 -30 44 -38 107 -50 108 -22 114
        -26 151 -100 l32 -66 -17 -49 c-14 -40 -16 -58 -7 -107 6 -32 12 -77 15 -101
        4 -40 3 -42 -17 -36 -12 4 -22 7 -23 8 -1 0 -5 9 -10 18 -8 15 -39 -6 -209
        -142 -110 -87 -200 -158 -201 -157 -1 1 2 27 8 57 14 74 3 176 -26 252 -13 35
        -54 125 -90 201 -98 202 -142 347 -165 540 -7 62 -5 71 15 98 35 48 47 118 42
        245 -3 62 -2 116 2 121 4 4 14 38 23 75 22 91 52 133 151 206 117 86 196 196
        245 337 28 82 64 320 56 371 l-6 42 53 12 c85 21 184 65 296 132 170 102 221
        158 256 277 23 78 39 97 101 120 125 47 910 287 1023 312 30 8 52 18 52 26 0
        7 -3 13 -6 13 -54 0 -1046 -305 -1126 -346 -32 -17 -47 -42 -78 -138 -29 -88
        -88 -150 -220 -231 -117 -72 -192 -106 -292 -134 -74 -21 -79 -21 -104 -5 -33
        22 -44 52 -44 116 0 44 2 49 18 43 40 -18 119 -35 159 -35 111 0 294 63 349
        121 34 35 24 51 -14 21 -86 -71 -248 -118 -367 -109 -88 6 -135 28 -135 61 0
        34 -51 52 -68 25 -25 -39 -108 -15 -160 47 -18 21 -37 44 -42 51 -16 20 9 822
        36 1192 13 175 14 206 2 218 -19 18 -47 7 -53 -21 -15 -74 -38 -543 -50 -996
        -3 -129 -10 -300 -16 -379 -11 -164 -77 -767 -113 -1038 -24 -176 -25 -185
        -10 -325 27 -267 20 -364 -33 -411 -19 -16 -19 -21 -7 -125 26 -216 65 -338
        185 -591 83 -174 99 -224 99 -312 0 -124 -39 -240 -138 -410 -142 -245 -233
        -349 -305 -349 -17 0 -82 10 -144 22 -119 23 -144 21 -151 -16 -5 -23 57 -101
        116 -149 26 -20 71 -44 100 -53 47 -14 57 -14 105 0 34 10 69 30 96 55 58 54
        43 74 -16 21 -84 -74 -167 -75 -264 -4 -44 32 -109 107 -109 126 0 13 38 10
        152 -14 134 -27 167 -20 242 55 64 64 100 116 197 284 l76 132 169 134 c93 74
        171 133 173 131 9 -8 0 -121 -15 -176 -32 -125 -130 -334 -229 -488 -72 -113
        -245 -321 -360 -435 -401 -395 -382 -378 -506 -439 -137 -66 -290 -114 -521
        -161 -95 -19 -249 -60 -343 -90 -304 -99 -560 -154 -818 -175 -148 -12 -417
        -14 -417 -2 0 4 7 47 14 97 24 146 36 373 36 630 -1 269 8 382 61 760 31 225
        39 442 38 1085 -2 625 11 977 46 1235 37 272 44 698 16 900 -13 92 -14 492 -1
        725 40 735 50 812 133 1029 36 95 38 97 166 193 88 66 98 71 160 78 113 12
        161 40 377 212 71 57 161 126 199 153 39 27 98 73 133 102 83 71 107 72 377
        17 248 -51 496 -86 610 -88 115 -1 144 10 172 70 21 43 13 90 -52 290 -43 133
        -65 212 -211 746 -71 262 -113 396 -143 458 -10 22 -18 40 -17 40 2 0 49 -24
        107 -54z m-3751 -1345 c0 -16 -11 -19 -82 -24 -144 -12 -168 -10 -168 12 0 22
        22 25 178 29 64 2 72 0 72 -17z m970 -276 c253 -29 518 -86 651 -140 63 -25
        184 -107 193 -130 3 -8 -1 -26 -9 -41 l-13 -26 -15 28 c-40 76 -125 127 -291
        173 -302 85 -527 111 -1026 120 -368 6 -385 7 -388 25 -4 19 6 19 379 13 240
        -4 435 -12 519 -22z m755 -466 c-54 -106 -86 -212 -85 -277 1 -31 -2 -108 -6
        -169 l-7 -113 -35 0 -35 0 7 72 c15 162 66 352 133 501 28 61 39 75 49 66 11
        -9 7 -24 -21 -80z m-6843 -329 c71 -47 158 -178 158 -236 0 -46 -96 -416 -119
        -462 -26 -52 -113 -117 -176 -133 -70 -18 -136 -6 -296 54 -236 89 -289 123
        -289 186 0 68 51 128 280 330 208 184 286 247 334 271 53 26 54 26 108 -10z
        m6734 -287 c-48 -226 -53 -563 -10 -704 3 -12 -17 -29 -76 -64 -45 -26 -84
        -45 -87 -41 -3 3 -11 44 -16 91 -18 143 13 407 74 625 35 123 33 120 80 120
        40 0 41 -1 35 -27z m2882 -1356 c23 -9 55 -17 70 -17 26 0 27 -2 29 -63 2 -82
        15 -113 58 -138 31 -19 35 -26 35 -62 0 -66 -38 -280 -60 -339 -44 -118 -117
        -215 -215 -285 -75 -54 -114 -94 -138 -138 l-22 -40 -3 30 c-2 17 8 113 22
        215 14 102 41 329 61 505 19 176 38 339 41 362 l6 43 37 -28 c20 -16 55 -36
        79 -45z m-8228 -227 c0 -35 -28 -77 -59 -90 -17 -7 -36 -18 -42 -26 -24 -29
        -6 -38 371 -182 85 -33 186 -74 223 -92 81 -40 137 -97 137 -140 0 -17 -3 -30
        -6 -30 -13 0 -312 157 -449 236 -172 99 -209 114 -276 114 -27 0 -49 4 -49 9
        0 5 -18 36 -40 69 -22 33 -40 66 -40 74 0 24 129 76 193 77 31 1 37 -2 37 -19z"/>
        <path d="M7502 9850 c-31 -19 -104 -120 -118 -162 -8 -27 6 -37 20 -14 86 142
        117 173 161 162 30 -8 31 -22 5 -56 -23 -30 -25 -40 -6 -40 15 0 39 32 50 66
        16 50 -56 79 -112 44z"/>
        <path d="M8064 9690 c-39 -16 -99 -98 -176 -241 -91 -169 -95 -179 -77 -179 8
        0 50 69 94 152 85 161 153 248 194 248 12 0 33 -12 48 -26 31 -31 27 -69 -13
        -121 -29 -38 -27 -51 4 -32 56 35 73 133 31 174 -27 26 -74 38 -105 25z"/>
        <path d="M8560 9581 c0 -5 14 -11 31 -15 43 -8 93 -64 119 -133 24 -62 60
        -235 60 -283 0 -21 5 -30 16 -30 13 0 14 9 9 58 -22 185 -77 340 -139 387 -32
        24 -96 35 -96 16z"/>
        <path d="M8360 9517 c-14 -7 -55 -52 -92 -101 -37 -49 -92 -115 -124 -147 -31
        -31 -54 -63 -52 -70 7 -20 101 73 183 181 102 135 102 135 167 88 28 -20 34 1
        7 26 -32 31 -58 38 -89 23z"/>
        <path d="M9070 9181 c0 -5 18 -40 40 -77 54 -92 96 -195 111 -272 10 -55 9
        -245 -3 -326 -2 -10 2 -21 9 -23 18 -6 28 75 28 217 0 103 -4 138 -23 200 -35
        110 -129 290 -152 290 -6 0 -10 -4 -10 -9z"/>
        <path d="M6453 9001 c-151 -110 -250 -340 -283 -661 -18 -170 -46 -260 -119
        -382 -16 -26 -19 -38 -10 -43 12 -8 15 -5 58 70 50 85 78 189 100 365 11 91
        28 192 36 225 38 146 112 289 191 368 47 47 76 62 111 55 28 -5 28 -73 -2
        -245 -29 -172 -30 -186 -13 -181 14 5 66 293 67 368 2 91 -58 118 -136 61z"/>
        <path d="M6663 8694 c5 -268 14 -350 58 -496 28 -96 128 -308 145 -308 19 0
        19 0 -31 101 -25 49 -59 129 -75 177 -54 163 -63 227 -69 511 -5 210 -9 265
        -20 269 -11 4 -12 -39 -8 -254z"/>
        <path d="M8821 8257 c-8 -10 -9 -17 -1 -22 31 -19 -6 -197 -71 -337 -37 -81
        -41 -95 -36 -146 7 -74 50 -225 65 -230 18 -6 14 24 -18 137 -34 117 -32 138
        20 241 61 120 98 314 68 353 -12 17 -16 17 -27 4z"/>
        <path d="M6258 7614 c-18 -18 -1 -21 130 -28 372 -18 679 -103 875 -242 48
        -35 67 -42 67 -24 0 27 -206 143 -335 188 -92 32 -258 70 -380 86 -138 19
        -348 30 -357 20z"/>
        <path d="M10576 8109 c-3 -17 -6 -55 -6 -84 0 -29 -9 -120 -19 -201 -21 -158
        -31 -289 -56 -734 -14 -243 -28 -433 -51 -677 -5 -53 -4 -73 5 -73 6 0 13 6
        16 13 9 29 37 393 60 788 21 361 50 717 60 727 1 1 5 -29 9 -68 13 -126 42
        -164 74 -97 29 61 55 297 32 297 -12 0 -17 -25 -24 -117 -7 -85 -26 -173 -38
        -173 -4 0 -8 6 -8 13 0 6 -5 52 -10 101 -5 49 -10 140 -11 203 -1 83 -5 113
        -14 113 -7 0 -15 -14 -19 -31z"/>
        <path d="M10939 7793 c0 -5 0 -60 1 -123 1 -136 -17 -262 -95 -660 -60 -305
        -89 -486 -79 -501 11 -19 22 2 29 58 10 83 41 264 76 438 92 461 115 651 93
        764 -6 27 -23 44 -25 24z"/>
        <path d="M11229 7523 c-294 -650 -354 -893 -404 -1638 -17 -244 -63 -598 -95
        -728 -11 -44 -33 -104 -50 -135 -25 -46 -30 -67 -30 -124 0 -72 11 -108 34
        -108 13 0 105 89 144 140 11 14 55 99 99 190 108 222 176 310 241 310 26 0
        107 -61 124 -93 14 -27 -7 -42 -68 -52 -50 -7 -57 -32 -8 -27 131 13 143 71
        32 160 -46 38 -58 43 -90 39 -77 -10 -135 -82 -245 -307 -83 -170 -157 -281
        -210 -315 l-23 -15 0 79 c0 44 4 83 10 86 14 9 48 90 66 158 20 78 80 497 88
        612 22 330 28 410 37 495 13 130 26 213 55 363 49 251 102 406 268 786 42 96
        76 178 76 183 0 4 -5 8 -10 8 -6 0 -25 -30 -41 -67z"/>
        <path d="M11583 7218 c2 -40 26 -167 52 -283 26 -115 53 -248 61 -295 39 -237
        1 -440 -172 -915 -42 -114 -70 -203 -65 -208 13 -13 20 4 99 224 82 228 133
        390 158 509 22 102 24 325 5 415 -7 33 -34 161 -61 285 -27 124 -52 251 -55
        283 -11 85 -29 72 -22 -15z"/>
        <path d="M9654 6930 c14 -151 69 -434 137 -698 161 -630 174 -706 167 -999 -3
        -134 -1 -183 8 -183 26 0 32 299 10 480 -23 178 -28 202 -176 775 -42 163 -79
        343 -100 495 -28 193 -30 200 -42 200 -7 0 -9 -22 -4 -70z"/>
        <path d="M9518 5713 c-47 -54 -85 -189 -95 -336 -5 -83 -4 -99 8 -95 10 3 16
        30 20 94 10 167 60 319 104 319 64 0 131 -308 179 -825 12 -128 46 -592 46
        -632 0 -5 7 -8 16 -8 13 0 15 8 10 38 -3 20 -8 93 -11 162 -23 461 -85 959
        -145 1161 -35 117 -91 169 -132 122z"/>
        <path d="M12215 5446 c-318 -80 -773 -234 -783 -265 -6 -17 19 -13 88 15 194
        79 461 163 721 228 147 36 198 57 157 63 -7 1 -89 -17 -183 -41z"/>
        <path d="M8005 4970 c-34 -66 -43 -331 -19 -523 10 -77 30 -53 24 29 -17 231
        0 494 31 494 30 0 82 -188 163 -590 92 -460 119 -808 81 -1050 -21 -134 -21
        -132 -9 -137 24 -8 54 213 54 397 0 235 -44 543 -140 990 -28 129 -60 264 -72
        300 -38 117 -81 151 -113 90z"/>
        <path d="M12326 4931 c-55 -68 -140 -147 -230 -211 -40 -29 -60 -60 -39 -60 3
        0 40 26 82 57 81 61 161 138 226 217 l40 49 25 -39 c14 -22 53 -57 90 -81 70
        -45 150 -111 150 -123 0 -4 -37 -11 -82 -15 -95 -8 -145 -27 -207 -79 -50 -42
        -77 -51 -131 -42 -43 7 -54 -6 -21 -24 47 -25 94 -12 169 44 74 56 130 76 212
        76 53 0 90 16 90 38 0 25 -72 91 -157 142 -49 30 -78 55 -93 82 -33 59 -58 52
        -124 -31z"/>
        <path d="M12075 4893 c-137 -31 -725 -270 -725 -294 0 -17 26 -9 170 55 265
        117 521 209 556 200 11 -3 28 2 39 10 17 14 18 17 5 26 -8 5 -17 9 -20 9 -3
        -1 -14 -3 -25 -6z"/>
        <path d="M10146 4638 c-3 -7 -6 -57 -8 -110 l-4 -97 -51 -74 c-32 -45 -76
        -132 -114 -223 -60 -145 -62 -153 -72 -276 -5 -70 -7 -134 -4 -142 16 -40 25
        -6 29 112 5 122 7 132 51 244 58 149 95 222 140 281 20 26 41 64 47 85 13 48
        13 199 0 207 -6 3 -12 0 -14 -7z"/>
        <path d="M12496 4563 c-4 -5 -60 -10 -124 -13 l-116 -5 -23 -34 c-37 -54 -31
        -107 24 -224 25 -55 49 -114 52 -130 4 -17 13 -33 20 -35 24 -8 6 63 -39 157
        -52 108 -63 155 -45 197 7 17 19 35 26 40 8 4 59 9 114 9 96 2 165 17 165 36
        0 11 -44 12 -54 2z"/>
        <path d="M11727 4393 c-11 -20 29 -126 97 -256 91 -173 106 -223 106 -342 0
        -172 -37 -237 -173 -305 -90 -45 -103 -55 -89 -64 14 -8 163 67 204 103 54 47
        79 117 85 236 8 151 -2 184 -145 457 -27 54 -53 116 -57 138 -6 37 -18 50 -28
        33z"/>
        <path d="M11825 4359 c-4 -6 -1 -17 6 -23 8 -7 53 -49 100 -93 125 -116 258
        -218 270 -206 7 7 -8 24 -43 50 -61 46 -132 107 -246 211 -51 46 -83 69 -87
        61z"/>
        <path d="M11043 4315 c-59 -16 -175 -72 -213 -103 -32 -26 -17 -42 18 -18 76
        49 194 97 254 102 55 5 69 2 110 -20 48 -26 72 -22 40 7 -27 25 -88 47 -124
        46 -18 0 -56 -7 -85 -14z"/>
        <path d="M10396 3685 c-22 -23 -12 -86 12 -71 5 3 7 18 4 32 -5 27 -5 27 44
        22 77 -8 104 -23 104 -55 0 -16 -9 -43 -20 -60 -22 -37 -44 -41 -129 -21 -43
        10 -53 9 -75 -6 -21 -13 -58 -70 -84 -128 -2 -5 3 -8 12 -8 9 0 30 25 47 56
        18 31 38 58 45 61 8 3 44 -1 80 -8 79 -16 106 -5 137 54 29 56 17 104 -30 123
        -60 25 -126 29 -147 9z"/>
        <path d="M11020 3610 c-64 -30 -80 -48 -80 -89 0 -45 21 -85 120 -225 111
        -157 132 -206 138 -332 5 -117 -5 -176 -74 -406 -24 -81 -44 -150 -44 -153 0
        -3 6 -5 13 -5 9 0 28 51 55 143 76 260 77 264 76 392 0 143 -15 192 -102 318
        -168 247 -173 257 -147 294 24 34 95 56 163 51 43 -3 83 -16 146 -46 96 -46
        106 -49 106 -29 0 7 -44 33 -97 57 -121 54 -201 63 -273 30z"/>
        <path d="M10582 3487 c-12 -13 -22 -26 -22 -30 0 -14 29 -7 38 9 13 24 41 11
        92 -41 52 -53 49 -71 -16 -94 -34 -11 -51 -11 -132 5 -87 16 -96 17 -145 1
        -69 -22 -152 -76 -138 -90 7 -7 23 -2 50 17 80 56 141 66 244 41 114 -27 223
        19 202 85 -8 26 -115 120 -135 120 -9 0 -26 -10 -38 -23z"/>
        <path d="M10100 2059 c-19 -17 -95 -74 -168 -126 -136 -97 -176 -140 -188
        -203 -7 -32 -6 -34 9 -21 9 7 17 19 17 25 0 32 62 94 173 172 148 106 218 164
        211 175 -8 14 -16 10 -54 -22z"/>
        <path d="M9235 1620 c-3 -5 15 -19 40 -31 27 -13 68 -46 97 -79 43 -49 83 -80
        103 -80 14 0 2 30 -14 35 -10 3 -38 28 -61 57 -42 49 -121 108 -146 108 -7 0
        -16 -4 -19 -10z"/>
        <path d="M9673 837 c-137 -113 -163 -132 -233 -170 -134 -74 -217 -92 -511
        -108 -266 -14 -388 -4 -574 47 -346 95 -636 146 -730 128 -58 -10 -39 -24 33
        -24 135 -1 400 -50 663 -124 174 -49 229 -56 441 -56 231 0 420 15 516 41 120
        32 239 99 357 199 33 28 72 60 86 70 l26 19 -5 -26 c-6 -33 12 -23 34 19 16
        30 13 58 -5 58 -6 0 -50 -33 -98 -73z"/>
        <path d="M675 6313 c-16 -9 -64 -46 -105 -83 -41 -36 -142 -121 -225 -187
        -145 -116 -201 -176 -229 -245 -7 -18 -10 -42 -7 -54 13 -44 71 -95 144 -125
        219 -91 323 -112 410 -85 106 32 156 95 189 235 11 48 33 135 49 194 41 151
        39 169 -20 254 -72 102 -138 133 -206 96z m95 -39 c3 -11 27 -53 54 -92 l49
        -72 -31 -107 c-17 -60 -40 -152 -52 -206 -35 -156 -84 -205 -210 -206 -62 -1
        -129 18 -277 77 -56 23 -108 39 -116 37 -17 -7 -57 31 -57 54 0 10 18 45 39
        78 29 45 72 87 168 164 70 56 173 141 228 189 55 48 109 92 120 98 31 18 78
        11 85 -14z"/>
        <path d="M5255 7898 c-2 -7 -27 -78 -55 -157 -91 -254 -109 -366 -75 -462 17
        -48 64 -102 107 -125 46 -24 39 0 -14 46 -62 53 -80 94 -80 180 0 84 21 166
        88 353 30 81 54 154 54 162 0 18 -19 20 -25 3z"/>
        <path d="M4355 7218 c0 -98 -6 -174 -19 -240 -25 -133 -19 -211 20 -274 24
        -39 39 -52 78 -66 27 -9 61 -29 76 -43 21 -20 30 -23 36 -14 9 16 -61 79 -88
        79 -32 0 -56 18 -82 63 -37 64 -39 94 -17 228 11 68 23 158 27 200 8 92 -3
        209 -19 209 -9 0 -12 -40 -12 -142z"/>
        <path d="M3872 7057 c-34 -40 -81 -197 -101 -337 -24 -157 -104 -428 -157
        -530 -31 -60 -61 -97 -70 -87 -14 14 -17 136 -4 235 9 80 40 196 75 285 13 33
        -20 18 -67 -30 -58 -60 -82 -109 -113 -226 -42 -163 -115 -721 -96 -740 15
        -15 17 -6 46 247 42 367 82 552 138 649 32 54 53 63 32 15 -45 -108 -68 -419
        -34 -454 24 -24 53 -10 88 42 68 104 145 342 187 582 35 199 80 342 104 327 6
        -4 10 -39 10 -80 0 -40 3 -80 6 -89 8 -20 50 -21 191 -4 57 6 106 8 110 4 5
        -4 -7 -21 -25 -36 -40 -33 -26 -51 16 -21 48 34 57 48 41 67 -14 17 -9 17
        -274 1 l-30 -2 -6 80 c-3 44 -9 88 -14 98 -8 20 -38 23 -53 4z"/>
        <path d="M5302 6798 c-50 -50 51 -299 243 -598 175 -274 199 -350 199 -605 0
        -169 -4 -205 -85 -880 -13 -108 -22 -246 -23 -345 -2 -244 8 -269 154 -373 59
        -43 104 -104 131 -177 12 -34 26 -59 31 -58 13 5 12 15 -12 80 -26 71 -72 132
        -130 175 -146 105 -145 103 -144 353 1 159 8 250 38 495 62 513 69 585 69 745
        -1 243 -31 332 -207 608 -143 224 -238 425 -248 522 -3 35 1 38 35 25 28 -11
        21 20 -8 33 -31 15 -28 15 -43 0z"/>
        <path d="M4717 6343 c4 -27 7 -280 7 -563 l-2 -515 -25 -70 c-14 -38 -37 -101
        -52 -140 -38 -101 -48 -285 -15 -285 6 0 10 39 10 93 0 77 5 106 31 177 16 47
        41 118 54 158 l25 73 2 507 c2 519 -2 612 -29 612 -12 0 -13 -9 -6 -47z"/>
        <path d="M1530 6258 c0 -12 11 -41 25 -63 47 -77 58 -110 63 -197 4 -70 8 -88
        20 -88 13 0 14 13 9 83 -7 93 -36 198 -69 247 -29 42 -48 49 -48 18z"/>
        <path d="M1962 6168 c-8 -8 -12 -58 -12 -157 0 -81 -4 -175 -9 -211 -14 -99
        -18 -295 -6 -298 6 -1 11 23 12 55 2 32 10 130 18 218 9 88 16 210 18 270 l2
        110 25 -3 c14 -2 54 -17 89 -34 l63 -30 -7 -42 c-10 -66 -11 -71 0 -74 15 -5
        39 81 31 111 -4 19 -24 35 -77 61 -73 37 -126 45 -147 24z"/>
        <path d="M5840 6163 c0 -4 16 -53 36 -108 135 -376 220 -874 258 -1505 4 -52
        13 -207 21 -345 33 -537 68 -817 118 -923 17 -36 24 -43 45 -40 16 2 33 14 43
        33 20 34 69 216 69 258 0 47 -18 29 -30 -30 -21 -98 -59 -220 -74 -232 -45
        -38 -98 260 -131 744 -8 121 -22 333 -30 470 -37 604 -91 990 -189 1345 -43
        157 -107 332 -123 338 -7 2 -13 0 -13 -5z"/>
        <path d="M1354 6103 c40 -132 50 -187 50 -268 1 -155 -50 -246 -183 -325 -79
        -47 -118 -84 -101 -95 6 -4 24 4 38 17 15 14 55 41 88 62 107 63 158 136 184
        261 14 65 8 133 -20 245 -29 115 -31 120 -47 120 -9 0 -12 -6 -9 -17z"/>
        <path d="M4342 6100 c-46 -19 -37 -61 15 -75 41 -10 53 13 14 26 -27 10 -30
        14 -17 21 35 20 140 20 216 0 84 -22 118 -13 54 14 -45 20 -245 30 -282 14z"/>
        <path d="M2336 5894 c-3 -9 -6 -31 -6 -49 0 -19 -16 -115 -35 -215 -19 -100
        -35 -196 -35 -214 0 -34 15 -56 37 -56 24 0 159 143 212 224 81 125 91 193 32
        214 -16 6 -35 20 -41 32 -8 16 -15 19 -22 12 -16 -16 16 -60 47 -68 18 -4 25
        -13 25 -30 0 -60 -79 -184 -184 -289 l-71 -70 -3 37 c-2 20 13 117 32 217 35
        177 45 271 27 271 -5 0 -12 -7 -15 -16z"/>
        <path d="M3815 5670 c-3 -6 0 -12 7 -14 7 -3 45 -14 83 -26 145 -44 185 -82
        341 -315 134 -201 160 -233 173 -220 7 7 -2 25 -26 55 -20 25 -82 115 -138
        200 -140 213 -195 262 -349 309 -78 24 -83 25 -91 11z"/>
        <path d="M2646 5593 c-9 -33 -44 -227 -61 -343 -18 -116 -24 -342 -15 -503 5
        -87 4 -89 -22 -112 -19 -16 -51 -27 -103 -36 -70 -11 -95 -22 -82 -36 8 -8
        149 18 178 33 54 27 54 27 55 308 1 266 11 367 59 594 8 40 15 82 15 93 0 24
        -17 25 -24 2z"/>
        <path d="M4860 5493 c-6 -32 -22 -92 -36 -133 -37 -114 -32 -145 26 -184 54
        -36 103 -11 131 69 9 24 17 31 31 27 22 -5 23 7 4 34 -18 24 -30 14 -65 -58
        -28 -57 -49 -68 -88 -47 -43 23 -45 41 -18 126 29 93 55 210 47 218 -14 13
        -21 1 -32 -52z"/>
        <path d="M2976 5391 c-7 -11 -23 -199 -31 -365 -5 -104 -4 -127 7 -124 10 4
        16 55 25 202 7 109 14 206 15 215 4 15 30 -118 44 -220 9 -72 -11 -150 -80
        -308 -93 -210 -100 -239 -93 -390 8 -178 26 -301 44 -301 12 0 14 8 9 33 -18
        107 -36 318 -30 367 9 69 38 153 103 298 28 62 56 132 62 155 20 77 9 216 -32
        400 -10 43 -29 61 -43 38z"/>
        <path d="M1515 5268 c-91 -127 -174 -217 -225 -243 -19 -10 -79 -26 -133 -36
        -124 -22 -191 -53 -195 -91 -5 -38 38 -57 237 -107 300 -75 355 -60 480 133
        78 120 70 138 -42 107 -82 -23 -113 -26 -122 -10 -4 5 0 18 9 28 19 21 21 44
        4 39 -6 -2 -20 -16 -31 -30 -15 -20 -17 -30 -8 -46 15 -29 56 -32 136 -12 38
        10 72 16 74 14 5 -5 -78 -132 -113 -172 -43 -50 -75 -62 -160 -62 -54 0 -117
        11 -228 39 -177 45 -210 58 -206 80 4 21 80 48 184 66 46 8 101 23 123 34 53
        27 125 99 196 196 32 44 74 100 92 124 29 39 35 61 15 61 -4 0 -43 -51 -87
        -112z"/>
        <path d="M3286 5292 c-3 -5 12 -33 33 -63 22 -30 82 -137 135 -239 54 -102
        109 -198 124 -214 34 -36 80 -56 130 -56 l39 0 7 -102 c3 -57 22 -200 40 -318
        48 -301 48 -302 36 -295 -5 3 -10 12 -10 19 0 20 -36 61 -43 50 -10 -16 47
        -118 67 -122 18 -4 19 2 13 89 -3 52 -20 175 -37 274 -39 223 -46 398 -26 660
        13 168 11 227 -7 198 -3 -5 -11 -76 -17 -158 -6 -83 -13 -177 -16 -210 l-5
        -61 -55 6 c-78 9 -106 41 -209 242 -117 227 -183 326 -199 300z"/>
        <path d="M2279 5165 c-35 -19 -84 -81 -154 -195 -32 -52 -68 -107 -81 -123
        -13 -15 -37 -53 -54 -85 -17 -32 -51 -81 -75 -109 -43 -50 -55 -73 -36 -73 16
        0 92 93 128 158 20 35 46 76 58 90 12 15 48 70 80 122 71 118 117 176 158 196
        29 15 33 15 64 -1 40 -22 57 -11 24 16 -27 22 -75 24 -112 4z"/>
        <path d="M4787 5009 c-40 -21 -70 -71 -83 -138 -11 -53 11 -34 37 31 20 53 31
        66 67 86 23 13 40 28 37 33 -8 12 -14 11 -58 -12z"/>
        <path d="M4237 4963 c-2 -4 -28 -84 -57 -178 -54 -173 -65 -234 -43 -242 14
        -5 83 65 83 85 0 21 -18 13 -44 -20 -14 -18 -26 -28 -26 -23 0 6 25 90 56 189
        36 116 52 182 46 188 -5 5 -12 6 -15 1z"/>
        <path d="M3294 4926 c-17 -8 -34 -21 -38 -31 -3 -9 -6 -71 -6 -137 0 -149 -22
        -305 -58 -406 -15 -42 -61 -132 -101 -200 -41 -67 -71 -126 -67 -129 11 -11
        31 16 101 132 112 187 142 302 152 568 6 167 6 169 30 178 13 5 28 9 33 9 16
        0 22 -59 29 -285 8 -258 9 -261 88 -349 25 -28 50 -61 57 -75 20 -39 36 -124
        36 -191 0 -49 3 -60 16 -60 15 0 16 11 9 92 -9 118 -33 180 -95 250 -74 83
        -80 103 -80 275 -1 147 -13 323 -26 356 -7 20 -37 21 -80 3z"/>
        <path d="M2875 4868 c-25 -73 -48 -125 -72 -165 -51 -83 -69 -165 -58 -268 4
        -36 12 -64 17 -63 5 2 10 53 12 113 l2 110 57 110 c59 112 79 175 58 175 -6 0
        -14 -6 -16 -12z"/>
        <path d="M4780 4610 c0 -6 39 -57 88 -114 48 -56 120 -141 160 -189 79 -94
        115 -119 254 -179 63 -27 119 -43 185 -52 116 -16 131 -28 201 -169 198 -398
        491 -1328 545 -1730 13 -98 33 -118 29 -29 -7 159 -117 588 -267 1037 -202
        608 -334 891 -425 908 -14 3 -59 9 -100 15 -86 10 -248 76 -313 126 -22 17
        -105 109 -186 205 -138 165 -171 198 -171 171z"/>
        <path d="M4234 4348 c-16 -12 -55 -46 -87 -75 -33 -30 -92 -76 -132 -103 -102
        -69 -120 -96 -134 -200 -6 -47 -21 -113 -32 -148 -21 -62 -21 -80 0 -67 16 9
        50 130 60 214 6 46 17 84 29 100 10 14 59 55 109 91 50 36 119 91 153 123 35
        31 67 57 71 57 5 0 16 -28 26 -62 10 -34 25 -64 32 -66 15 -5 13 6 -15 106
        -17 59 -33 66 -80 30z"/>
        <path d="M5133 3838 c-19 -24 -300 -623 -321 -683 -11 -33 -25 -87 -32 -120
        -7 -33 -39 -120 -72 -194 -71 -162 -86 -206 -132 -386 -43 -167 -63 -333 -50
        -425 8 -60 11 -65 34 -65 l25 0 6 155 c6 141 11 172 53 335 35 134 65 220 115
        335 38 85 77 191 86 235 24 107 59 190 215 515 133 277 140 300 98 308 -8 2
        -19 -3 -25 -10z"/>
        <path d="M5440 3573 c0 -50 50 -473 76 -648 77 -512 160 -755 258 -755 60 0
        130 82 168 195 19 59 23 91 23 200 0 139 -24 255 -51 255 -17 0 -18 8 6 -85
        48 -189 6 -428 -90 -509 -59 -49 -98 -28 -145 78 -71 161 -152 608 -203 1115
        -11 118 -21 176 -29 179 -9 2 -13 -6 -13 -25z"/>
        <path d="M7026 2436 c-87 -88 -169 -180 -183 -203 -17 -30 -27 -67 -33 -130
        -14 -135 -68 -289 -175 -503 -92 -183 -117 -355 -54 -375 23 -8 79 18 109 50
        31 33 7 43 -30 11 -58 -48 -90 -43 -90 15 0 57 44 191 105 319 111 234 165
        398 165 507 1 70 33 118 170 252 154 150 216 225 183 220 -5 -1 -79 -74 -167
        -163z"/>
        <path d="M6335 1458 c4 -222 9 -406 12 -410 2 -5 9 -8 15 -8 7 0 8 115 4 368
        -7 388 -11 452 -28 452 -7 0 -8 -128 -3 -402z"/>
        <path d="M7021 1575 c-74 -78 -86 -110 -84 -230 1 -91 25 -290 37 -302 17 -18
        21 16 10 89 -40 250 -30 328 52 418 51 54 65 80 45 80 -4 0 -31 -25 -60 -55z"/>
        <path d="M6585 845 c-33 -14 -61 -25 -62 -25 -2 0 -3 -7 -3 -15 0 -21 13 -19
        70 10 27 14 65 25 85 25 37 0 46 -11 60 -73 8 -41 39 -67 80 -67 20 0 82 16
        138 35 119 41 123 41 130 15 7 -29 27 -25 27 5 0 47 -37 49 -153 9 -66 -22
        -116 -34 -142 -32 -39 3 -40 4 -58 58 -10 30 -25 61 -34 68 -25 18 -72 14
        -138 -13z"/>
        </g>
    </svg>
    )
}

export const Hoodie = ({fill}) => {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 1000.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M4985 12753 c-73 -56 -276 -236 -442 -391 -110 -102 -179 -186 -674
            -813 l-551 -700 -55 -127 c-71 -164 -128 -320 -147 -402 -14 -57 -14 -72 -1
            -130 36 -155 131 -408 233 -614 56 -115 100 -211 98 -214 -3 -2 -352 -237
            -776 -521 l-771 -517 -349 -624 c-209 -372 -361 -656 -379 -704 -16 -45 -193
            -515 -394 -1046 l-365 -965 -91 -730 c-110 -882 -237 -1942 -245 -2045 -3 -41
            -22 -296 -41 -566 l-36 -491 41 -114 c46 -126 156 -489 250 -818 33 -118 62
            -216 63 -217 1 -2 83 15 182 38 l180 40 284 -16 c156 -9 285 -15 286 -14 2 2
            -8 146 -22 322 l-25 318 67 100 66 100 -21 196 c-12 108 -25 230 -31 270 -5
            40 -9 96 -9 124 0 28 51 312 114 632 l114 581 7 270 7 270 65 210 c35 116 98
            359 139 540 l75 330 10 264 10 265 108 205 c59 114 125 231 147 261 35 49 39
            52 41 30 1 -14 9 -234 18 -490 22 -679 44 -1231 55 -1425 9 -142 13 -172 29
            -190 34 -38 41 -63 51 -207 19 -251 97 -972 141 -1293 43 -324 152 -851 275
            -1334 30 -118 54 -222 54 -232 0 -11 2 -19 5 -19 3 0 87 18 188 39 925 199
            1663 301 2096 288 151 -4 219 -12 476 -55 301 -51 1032 -165 1545 -242 151
            -22 284 -43 296 -45 19 -4 38 32 187 353 l166 357 16 215 c14 197 92 1107 165
            1925 33 378 36 423 90 1530 22 451 41 827 43 835 5 28 172 -289 227 -430 33
            -84 33 -87 41 -320 15 -390 26 -516 53 -615 47 -170 150 -689 220 -1110 14
            -82 39 -271 56 -420 17 -148 40 -337 52 -420 20 -141 21 -164 10 -380 -6 -126
            -23 -374 -37 -550 l-27 -320 56 -377 c31 -208 56 -380 56 -383 0 -3 249 -5
            554 -5 l555 0 10 53 c5 28 27 142 47 252 21 113 66 305 105 443 l69 243 -40
            897 -41 897 -190 1245 -190 1245 -274 870 c-150 479 -279 890 -287 915 -7 24
            -112 228 -235 452 -356 653 -392 713 -489 824 -118 134 -188 223 -207 260 -21
            41 -68 78 -192 149 -170 98 -831 423 -1193 586 -44 20 -61 33 -56 41 5 7 98
            153 208 323 109 171 214 333 231 360 l32 50 -341 647 -341 647 -369 451 -369
            452 -161 122 c-274 209 -491 356 -525 356 -9 0 -43 -21 -76 -47z"/>
            </g>
        </svg>
    )
}

export const Shirts = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 1200.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M5660 12794 c-350 -19 -714 -75 -837 -130 -105 -46 -152 -109 -294
            -389 l-96 -190 -109 -48 c-60 -27 -210 -97 -334 -157 -124 -59 -272 -127 -330
            -150 -335 -136 -585 -278 -940 -536 -207 -150 -321 -247 -465 -395 -257 -265
            -420 -489 -495 -680 -11 -28 -66 -155 -121 -282 -127 -292 -165 -387 -223
            -561 -56 -164 -116 -301 -301 -686 -235 -488 -258 -577 -275 -1070 -5 -151
            -14 -304 -20 -340 -31 -188 -88 -373 -254 -824 -101 -273 -123 -359 -206 -811
            -142 -773 -162 -875 -219 -1105 -95 -381 -114 -533 -99 -825 9 -185 25 -311
            74 -590 43 -241 52 -311 48 -350 -2 -16 -11 -100 -20 -185 -9 -85 -24 -206
            -35 -268 -10 -62 -26 -223 -34 -359 -13 -202 -21 -270 -45 -374 -35 -147 -35
            -157 -14 -187 34 -48 82 -55 550 -82 198 -11 455 -27 571 -36 117 -8 217 -13
            223 -9 16 10 28 74 40 205 29 346 38 771 20 930 -5 47 -28 141 -50 209 -57
            179 -57 175 -16 256 40 79 152 410 190 561 14 55 30 136 35 180 6 50 35 160
            81 299 99 306 186 593 345 1140 368 1266 459 1553 695 2175 59 157 125 335
            146 395 25 73 48 120 67 139 16 16 32 39 36 50 9 22 11 16 45 -105 10 -34 46
            -102 96 -179 83 -128 105 -180 94 -227 -3 -15 -44 -152 -90 -303 -46 -152 -95
            -325 -109 -385 -56 -248 -78 -556 -79 -1095 0 -427 11 -578 64 -882 55 -314
            82 -512 83 -598 0 -80 -3 -94 -62 -248 -148 -393 -188 -602 -252 -1312 -15
            -169 -37 -329 -65 -466 -13 -67 -24 -152 -24 -190 0 -38 -5 -139 -11 -224
            l-12 -155 38 -90 c61 -150 144 -299 211 -384 156 -195 434 -336 954 -487 69
            -19 222 -67 340 -104 327 -105 764 -211 1030 -251 238 -36 812 -35 967 1 23 5
            156 24 295 40 636 77 1110 169 1548 302 225 68 352 116 575 220 72 33 159 74
            195 90 116 54 304 195 395 297 57 64 137 175 162 225 36 71 38 129 13 406 -34
            383 -42 436 -150 930 -98 450 -125 566 -191 810 -28 104 -30 125 -26 220 3 58
            11 150 17 205 14 119 7 657 -14 1044 -15 268 -13 309 25 466 19 81 21 109 16
            275 -4 154 -11 218 -41 385 -35 189 -112 578 -171 855 -35 164 -31 222 29 400
            46 137 66 250 66 367 0 34 4 64 8 67 20 12 285 -550 432 -917 72 -180 156
            -380 186 -444 29 -63 54 -121 54 -128 0 -7 -18 -35 -41 -61 -60 -73 -81 -120
            -87 -195 -5 -76 8 -125 49 -186 16 -23 29 -47 29 -53 0 -6 16 -20 35 -31 34
            -20 112 -115 141 -171 8 -15 23 -92 34 -169 25 -179 47 -272 110 -461 59 -177
            137 -483 180 -705 29 -151 62 -271 82 -303 32 -53 191 -389 191 -405 -1 -11
            -15 -85 -33 -165 -36 -158 -36 -200 -10 -545 13 -181 13 -378 0 -535 -14 -164
            -12 -418 3 -499 l12 -64 230 -3 c127 -2 361 -11 520 -19 160 -9 349 -17 420
            -18 160 -2 180 6 191 80 10 69 -2 399 -27 703 -27 339 -22 512 21 764 24 143
            36 190 59 231 51 90 68 232 77 640 8 361 -1 481 -76 965 -67 441 -108 658
            -150 800 -50 165 -188 500 -229 555 -25 33 -49 84 -70 147 l-31 98 15 102 c21
            135 21 312 0 411 -9 43 -31 125 -50 183 -19 58 -43 149 -55 202 -36 170 -33
            163 -280 672 -374 772 -549 1185 -734 1733 -224 665 -454 978 -998 1362 -308
            217 -359 248 -688 420 -124 65 -267 142 -317 171 -104 59 -221 113 -591 269
            -139 59 -266 118 -282 132 -16 13 -35 43 -42 66 -32 107 -232 346 -361 432
            -114 75 -259 95 -757 99 -195 2 -384 2 -420 0z m803 -99 c172 -22 276 -64 351
            -138 49 -50 74 -97 123 -238 33 -97 36 -114 40 -254 3 -82 1 -161 -4 -174 -9
            -21 -11 -17 -27 40 -53 189 -148 271 -346 299 -166 23 -464 42 -675 42 -282 0
            -600 -17 -678 -36 -78 -19 -175 -66 -222 -107 -44 -39 -95 -114 -95 -141 0
            -47 -23 -8 -91 156 -29 69 -69 239 -69 294 0 40 50 84 154 133 109 52 208 76
            411 99 315 37 935 50 1128 25z m535 -232 c34 -44 150 -272 217 -429 59 -136
            123 -316 175 -487 39 -132 161 -609 156 -614 -6 -6 -198 209 -209 234 -16 38
            -102 159 -146 206 -53 56 -104 79 -189 85 -62 4 -72 7 -66 21 18 43 76 254 96
            349 16 78 22 144 22 242 0 148 -8 186 -70 336 -19 46 -34 85 -34 88 0 16 22 2
            48 -31z m-2194 -360 c72 -166 162 -482 139 -490 -18 -6 -112 -161 -176 -289
            -65 -130 -103 -224 -132 -322 l-22 -73 -12 84 c-7 47 -37 156 -67 243 -95 275
            -96 278 -96 399 0 91 4 126 26 200 29 94 95 236 146 311 17 24 37 61 44 82 8
            22 24 59 37 83 l22 44 13 -62 c7 -35 42 -129 78 -210z m1451 137 c197 -13 318
            -32 425 -66 90 -28 136 -65 198 -158 29 -46 32 -56 32 -131 0 -48 -8 -115 -20
            -161 -25 -101 -123 -296 -200 -399 -184 -246 -432 -449 -623 -510 -115 -37
            -124 -35 -151 28 -13 28 -26 65 -30 80 -4 15 -14 30 -24 33 -9 3 -25 10 -34
            15 -25 13 -28 12 -28 -9 0 -10 -9 -25 -20 -35 -22 -19 -25 -15 -10 13 12 22 3
            50 -16 50 -8 0 -14 5 -14 11 0 7 7 8 18 4 21 -9 102 -19 102 -13 0 2 -16 42
            -35 88 -19 46 -35 88 -35 94 0 5 -18 24 -40 43 -22 19 -53 62 -71 98 -26 53
            -38 67 -75 84 -62 30 -74 28 -74 -13 0 -18 -3 -47 -6 -64 -5 -27 -2 -33 25
            -50 39 -24 96 -99 80 -104 -19 -6 7 -45 40 -63 23 -11 31 -24 36 -57 4 -24 14
            -56 22 -71 16 -32 12 -45 -7 -22 -9 11 -32 15 -85 15 l-72 0 -6 43 c-3 23 -2
            44 4 45 17 6 9 59 -11 77 -11 10 -20 29 -20 43 0 18 -22 46 -80 103 -93 90
            -107 119 -39 84 36 -19 53 -11 44 22 -4 17 -2 23 6 18 10 -7 28 10 29 27 0 16
            -94 55 -185 77 -113 27 -191 59 -224 95 -19 21 -70 140 -102 241 -5 16 -9 58
            -9 91 0 89 36 155 115 209 78 53 120 61 382 80 378 26 559 30 788 15z m1455
            -391 c566 -288 768 -399 1023 -562 60 -38 173 -103 250 -144 225 -119 295
            -171 463 -338 173 -172 256 -287 359 -500 85 -175 216 -508 334 -847 104 -299
            274 -691 508 -1168 210 -429 388 -843 437 -1020 8 -30 25 -111 37 -180 19
            -109 21 -159 20 -385 l-2 -260 31 -85 c18 -47 47 -111 66 -142 115 -188 241
            -546 293 -833 37 -200 131 -843 141 -963 12 -149 13 -632 0 -780 -8 -99 -13
            -121 -40 -168 -35 -62 -90 -278 -115 -445 -8 -59 -15 -164 -15 -234 l0 -127
            -37 7 c-94 18 -190 26 -498 40 -377 18 -554 35 -590 59 -74 48 -79 151 -22
            396 19 85 38 173 42 195 9 59 -26 160 -101 295 -63 114 -104 214 -104 254 0
            12 -13 61 -30 109 -16 48 -46 165 -66 260 -54 259 -83 375 -111 437 -44 98
            -90 242 -148 466 -89 346 -108 395 -178 473 -115 128 -119 153 -56 331 66 189
            62 299 -17 407 -79 109 -97 144 -133 258 -21 66 -48 170 -60 230 -39 188 -45
            202 -167 400 -65 105 -135 227 -156 272 -37 78 -78 235 -94 355 -5 35 -19 71
            -42 106 -19 28 -37 52 -40 52 -2 0 -2 -15 0 -32 8 -51 -9 -169 -41 -296 -20
            -81 -31 -152 -35 -232 -7 -138 -30 -230 -89 -343 -63 -123 -60 -174 26 -572
            71 -327 148 -732 167 -875 7 -47 13 -143 13 -215 1 -110 -2 -141 -21 -200 -39
            -121 -48 -257 -29 -483 40 -485 46 -904 16 -1072 -7 -36 -12 -128 -12 -205 1
            -119 5 -154 27 -235 58 -213 99 -384 181 -760 102 -468 120 -559 134 -705 6
            -60 18 -182 27 -270 10 -99 13 -189 9 -235 -6 -67 -11 -81 -45 -125 -59 -77
            -245 -255 -338 -323 -422 -311 -1031 -525 -1787 -627 -66 -9 -166 -30 -223
            -46 -57 -15 -151 -35 -210 -44 -126 -18 -407 -25 -411 -10 -10 38 -14 834 -7
            1230 6 314 5 858 -3 1575 -7 602 -16 1534 -21 2070 -5 536 -13 1243 -20 1570
            -7 372 -8 987 -4 1640 5 890 4 1089 -10 1342 -22 404 -21 540 3 609 26 74 168
            413 198 474 35 68 159 275 250 414 41 62 72 115 70 117 -5 5 -168 -188 -259
            -306 -94 -122 -114 -157 -162 -288 -24 -64 -69 -182 -99 -262 l-54 -145 -8
            -155 c-5 -85 -8 -164 -8 -175 1 -77 15 -372 25 -530 15 -219 13 -541 -8 -1640
            -9 -475 -11 -782 -5 -801 6 -20 5 -36 -1 -42 -10 -10 16 -899 31 -1046 4 -43
            4 -100 0 -125 -11 -66 -11 -188 1 -273 16 -119 31 -1536 19 -1864 -7 -204 -7
            -290 1 -299 8 -9 8 -15 0 -20 -16 -10 -12 -62 5 -69 21 -8 21 -124 0 -132 -20
            -8 -20 -92 0 -118 18 -23 22 -236 5 -236 -13 0 -13 -97 0 -102 7 -2 11 -334
            11 -1003 0 -1187 0 -1280 -12 -1210 -9 58 -26 1500 -39 3460 -5 754 -17 1640
            -26 1970 -12 479 -15 914 -12 2155 2 1153 0 1607 -9 1756 -20 322 5 701 57
            876 11 37 24 70 29 73 6 3 17 30 26 60 8 30 20 55 25 55 6 0 10 8 10 19 0 50
            30 90 112 147 180 125 252 195 400 389 l53 69 98 13 c166 23 189 9 342 -212
            99 -144 310 -363 329 -344 7 7 -14 264 -30 364 -33 207 -104 432 -224 710 -28
            66 -54 126 -57 134 -8 18 -51 38 437 -210z m-3344 -114 l-7 -205 43 -130 c23
            -72 46 -130 50 -130 3 0 9 8 11 18 2 9 2 1 0 -18 -2 -21 6 -69 21 -116 14 -44
            35 -124 46 -177 22 -111 45 -171 60 -162 17 10 104 199 155 336 25 68 65 153
            87 189 49 77 147 189 166 190 30 0 135 -77 190 -139 31 -36 68 -75 82 -86 42
            -33 73 -69 118 -136 39 -57 43 -70 42 -119 -2 -71 1 -82 10 -45 7 29 8 29 9
            -10 1 -22 13 -60 26 -85 l25 -45 -2 83 c0 45 2 85 5 88 17 18 23 -17 21 -123
            -2 -95 2 -139 21 -228 l25 -110 -4 105 c-7 181 12 85 23 -120 6 -107 16 -271
            22 -365 6 -93 15 -438 19 -765 4 -327 13 -707 19 -845 6 -137 16 -898 22
            -1690 6 -792 15 -1606 20 -1810 13 -542 19 -1699 9 -1640 -4 28 -9 235 -9 460
            -1 377 -11 727 -28 965 l-7 95 -8 -120 c-13 -211 11 -1465 34 -1745 6 -80 16
            -148 21 -151 5 -4 2 -17 -6 -30 -9 -13 -19 -43 -23 -65 -5 -34 -3 -45 15 -61
            21 -19 21 -23 11 -189 -14 -226 -30 -1982 -19 -2159 14 -241 13 -356 -2 -366
            -67 -43 -707 73 -1269 230 -183 51 -267 78 -404 131 -60 23 -180 64 -265 90
            -445 138 -489 154 -596 212 -118 63 -197 170 -331 444 l-83 171 9 217 c7 151
            16 244 30 304 28 125 47 265 70 532 33 389 60 538 172 959 108 408 126 485
            138 571 12 85 7 180 -11 227 -9 23 -77 420 -98 573 -33 231 -43 388 -48 705
            -8 519 13 698 142 1217 88 352 117 516 118 658 0 116 -8 142 -54 165 -47 25
            -96 112 -125 222 -27 103 -34 119 -65 128 -10 4 -22 17 -25 30 -5 21 -17 26
            -82 40 -43 9 -82 18 -89 20 -7 3 -15 -11 -19 -33 -60 -346 -156 -663 -414
            -1357 -198 -533 -282 -806 -430 -1395 -155 -614 -239 -900 -340 -1145 -44
            -108 -84 -281 -120 -525 -35 -241 -65 -339 -166 -542 -30 -59 -63 -139 -74
            -179 -18 -68 -19 -77 -5 -143 23 -112 20 -168 -12 -206 -26 -31 -29 -32 -82
            -27 -31 3 -72 9 -92 13 -34 6 -37 4 -47 -25 -14 -41 -19 -39 -14 5 4 31 2 35
            -14 32 -11 -2 -21 -15 -25 -36 -10 -50 -23 -53 -16 -5 5 40 4 43 -19 43 -20 0
            -24 -5 -24 -29 0 -17 -4 -33 -10 -36 -6 -4 -10 10 -10 34 0 33 -4 41 -19 41
            -12 0 -22 -11 -29 -32 l-11 -33 2 35 c2 29 -2 36 -20 38 -18 3 -22 -2 -25 -29
            -2 -18 -9 -34 -17 -37 -10 -3 -12 4 -6 37 6 37 5 41 -14 41 -16 0 -22 -8 -27
            -40 -4 -22 -11 -40 -16 -40 -5 0 -6 19 -2 45 6 41 5 45 -15 45 -15 0 -21 -6
            -21 -22 0 -32 -11 -68 -20 -68 -4 0 -5 20 -3 44 l5 43 -38 6 c-22 4 -73 7
            -114 7 -134 0 -223 39 -252 110 -6 14 -14 117 -18 230 -7 166 -13 225 -34 310
            -14 58 -30 137 -35 175 -6 39 -20 131 -32 205 -51 328 -35 493 107 1105 60
            255 129 566 234 1050 88 404 94 423 184 608 117 239 187 456 235 727 30 166
            37 221 71 560 54 537 92 701 213 910 128 222 242 468 414 895 169 420 194 478
            274 638 168 338 364 612 606 847 290 283 558 457 1159 753 142 70 367 181 499
            246 132 66 244 120 249 120 5 1 6 -84 2 -204z m1479 -1002 c19 -45 38 -95 41
            -110 12 -61 84 -136 77 -80 -2 11 6 21 21 27 13 5 34 24 47 42 32 46 65 83
            133 152 63 64 88 63 38 -1 -16 -21 -49 -81 -72 -135 -23 -53 -50 -104 -60
            -113 -38 -35 -54 -125 -70 -410 -5 -104 -13 -210 -17 -235 -6 -39 -8 -30 -13
            57 -3 57 -9 105 -12 108 -3 4 -20 7 -37 7 -32 1 -32 1 -25 44 5 35 3 45 -10
            50 -17 7 -20 34 -10 97 6 31 11 37 30 37 13 0 24 4 24 10 0 9 -47 60 -55 60
            -5 0 -36 -134 -54 -239 -9 -52 -21 -96 -26 -98 -7 -2 -20 111 -35 297 -22 275
            -29 322 -43 265 -3 -13 -5 -7 -7 15 -1 29 -3 32 -10 15 -8 -18 -9 -17 -9 6 -1
            15 6 32 14 39 21 18 19 30 -6 30 -18 0 -20 4 -15 26 5 17 3 23 -4 19 -15 -9
            -12 17 5 39 8 10 25 24 37 30 13 6 30 25 38 43 l15 32 17 -22 c10 -12 33 -59
            53 -104z m-195 -179 c5 -66 14 -207 20 -314 5 -107 13 -226 15 -265 4 -49 3
            -65 -4 -54 -6 9 -17 137 -25 285 -19 334 -26 431 -32 469 -4 23 -3 26 5 14 6
            -9 15 -70 21 -135z m46 -1121 c-5 -259 -3 -468 4 -573 17 -267 43 -2068 32
            -2242 -3 -56 18 -1579 27 -1938 23 -888 29 -1583 15 -1787 -18 -249 -33 -914
            -35 -1513 -1 -267 -5 -597 -9 -735 -5 -143 -6 181 -4 760 3 868 6 1058 24
            1349 15 266 18 363 10 450 -5 61 -10 311 -10 556 -1 245 -5 625 -10 845 -5
            220 -14 859 -20 1420 -17 1666 -29 2489 -41 2865 -13 383 -5 950 13 950 7 0 8
            -136 4 -407z m73 -8 c-5 -60 -12 -124 -15 -141 -3 -18 -1 -41 5 -53 7 -12 8
            -21 2 -21 -5 0 -7 -21 -5 -47 11 -124 20 -189 27 -201 5 -8 2 -23 -7 -37 -20
            -30 -20 -87 -1 -230 8 -60 15 -112 15 -115 0 -3 5 2 10 10 6 9 10 225 11 555
            1 340 4 527 10 505 18 -69 23 -1101 8 -1650 -16 -589 -15 -1705 1 -2305 5
            -214 14 -950 20 -1635 5 -685 15 -1360 20 -1500 15 -359 26 -2360 13 -2360 -6
            0 -12 277 -17 793 -3 435 -13 1098 -21 1472 -8 374 -20 1182 -26 1795 -6 613
            -15 1252 -20 1420 -5 168 -11 715 -13 1216 -2 501 -6 917 -11 923 -4 7 0 17 9
            24 14 10 17 50 22 292 3 154 8 283 10 287 2 4 -2 11 -11 15 -12 7 -13 16 -6
            40 10 35 4 71 -15 90 -28 28 -39 213 -39 643 0 239 4 438 9 455 16 49 25 -110
            15 -240z m132 -105 c12 -230 4 -765 -11 -725 -4 11 -9 245 -12 520 -5 520 3
            589 23 205z m103 243 c20 -122 28 -341 24 -654 -2 -277 -6 -351 -17 -361 -11
            -11 -13 14 -8 157 5 174 -6 680 -19 818 -4 50 -3 77 3 77 6 0 13 -17 17 -37z
            m-43 -310 c13 -245 7 -600 -10 -630 -7 -13 -10 69 -11 292 0 171 -2 389 -5
            485 -6 238 10 147 26 -147z m-177 -870 c-10 -40 -14 -41 -14 -5 0 18 5 32 10
            32 6 0 8 -11 4 -27z m114 -87 c4 -47 12 -86 17 -86 6 0 11 29 13 65 3 69 15
            85 26 34 9 -47 7 -429 -4 -714 -10 -265 -17 -345 -30 -345 -4 0 -5 101 -2 224
            4 168 2 225 -7 231 -7 4 -14 5 -16 3 -2 -2 -9 -107 -15 -233 -11 -247 -18
            -315 -31 -315 -5 0 -6 48 -4 108 16 327 25 619 25 763 0 161 10 363 17 356 2
            -2 7 -43 11 -91z m118 -438 c-4 -266 -12 -519 -16 -563 -5 -48 -7 143 -5 473
            3 304 7 557 10 563 4 5 9 9 12 9 4 0 3 -217 -1 -482z m51 -1558 c-1 -314 -1
            -322 -7 -135 -4 110 -7 380 -7 600 l1 400 7 -265 c3 -146 6 -416 6 -600z
            m-203 703 c4 -27 11 -349 16 -718 5 -368 14 -776 19 -905 12 -287 13 -820 2
            -875 -7 -34 -9 -27 -10 45 -4 317 -39 1977 -47 2273 -4 145 -3 227 4 227 5 0
            12 -21 16 -47z m76 -223 c12 -246 12 -916 0 -850 -9 50 -30 702 -30 930 0 212
            18 164 30 -80z m63 68 c14 -308 18 -995 6 -963 -20 55 -40 1115 -21 1115 5 0
            12 -69 15 -152z m-31 -1694 c10 -481 7 -627 -12 -608 -5 5 -11 266 -15 579 -4
            420 -3 565 5 552 7 -11 15 -187 22 -523z m68 -62 c12 -628 12 -624 1 -597 -12
            30 -21 212 -30 620 -9 386 -5 578 10 537 5 -12 13 -264 19 -560z m64 -797 c3
            -330 2 -593 -3 -585 -5 8 -10 321 -13 695 -2 374 -1 637 3 585 4 -52 9 -365
            13 -695z m-186 -875 c1 -102 -2 -207 -6 -235 l-8 -50 -7 64 c-9 84 -9 946 1
            1036 7 69 18 -373 20 -815z m60 325 c-2 -410 -6 -537 -15 -547 -10 -10 -13 39
            -13 242 0 384 12 840 22 840 5 0 8 -224 6 -535z m67 -432 c-4 -69 -11 -113
            -18 -113 -12 0 -1 1035 11 1054 9 14 15 -797 7 -941z m-124 -640 c6 -158 12
            -294 14 -300 3 -7 9 -13 15 -13 7 0 10 99 11 293 1 182 4 275 9 247 22 -119
            58 -2147 44 -2435 -13 -274 -22 -129 -24 390 -4 872 -14 1453 -27 1466 -21 21
            -26 -114 -19 -508 5 -291 4 -383 -5 -389 -8 -5 -10 -2 -7 7 7 17 -16 933 -32
            1289 -12 288 -12 280 0 258 6 -9 15 -146 21 -305z m129 -125 c23 -812 37
            -2051 23 -2179 -14 -135 -20 -4 -22 491 -1 277 -6 709 -11 960 -19 908 -21
            1136 -11 1113 6 -12 15 -185 21 -385z m69 -323 c17 -678 26 -1888 14 -1909 -7
            -13 -12 152 -17 535 -12 1049 -26 1934 -30 1995 -3 34 -2 65 2 69 10 10 19
            -190 31 -690z m4666 445 c132 -8 706 -61 712 -65 5 -3 42 -269 58 -417 16
            -141 19 -507 4 -522 -16 -16 -303 -37 -500 -37 -260 0 -400 14 -615 62 -109
            24 -100 5 -110 237 -5 105 -1 242 11 447 l19 294 45 4 c94 7 231 6 376 -3z
            m-4974 -1135 c1 -501 -2 -922 -6 -937 -23 -88 -26 14 -21 720 5 690 14 1137
            22 1130 1 -2 4 -413 5 -913z m-5216 785 c156 -11 726 -88 738 -99 17 -17 19
            -341 3 -606 -9 -148 -19 -282 -22 -297 -5 -25 -8 -27 -92 -34 -193 -16 -637
            10 -1017 59 l-100 13 3 105 c4 141 28 425 47 549 14 92 50 277 60 308 5 15
            180 16 380 2z"/>
            <path d="M5905 8160 c-10 -42 -1 -385 10 -383 13 4 19 368 6 391 -8 15 -10 13
            -16 -8z"/>
            </g>
            </svg>
        )
}

export const Pants = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 640 1300"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M1430 12583 l0 -170 -37 -24 c-29 -18 -49 -46 -84 -115 -255 -508
            -486 -2524 -714 -6224 -63 -1035 -98 -1657 -225 -4040 -44 -816 -84 -1525
            -100 -1740 -5 -74 -10 -152 -10 -172 l0 -38 1233 0 1233 0 13 158 c59 752 180
            1959 401 4027 197 1845 246 2314 355 3405 35 360 66 664 68 675 8 58 46 -1222
            72 -2480 26 -1254 38 -2631 31 -3605 -6 -890 -21 -1983 -30 -2117 l-4 -63
            1261 0 1260 0 -7 208 c-3 114 -8 936 -11 1827 -10 2798 -27 4712 -55 5945 -57
            2510 -158 3911 -312 4313 -30 77 -32 92 -35 240 -4 156 -4 157 -27 157 -13 0
            -117 -7 -232 -16 -179 -14 -461 -16 -1979 -17 -1755 -2 -1799 -1 -2017 29
            l-48 7 0 -170z"/>
            </g>
        </svg>
    )
}

export const WidePants = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 672.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M1180 12794 c0 -60 -91 -1511 -130 -2084 -235 -3415 -538 -6344
            -1032 -9967 -12 -90 -19 -165 -16 -169 4 -3 120 -26 260 -50 139 -24 509 -89
            823 -144 314 -55 802 -140 1085 -190 685 -120 709 -124 716 -118 2 3 9 273 14
            599 30 1885 143 3931 405 7379 30 393 68 900 85 1125 17 226 35 419 40 430 7
            17 9 16 9 -10 1 -16 8 -116 16 -220 37 -493 77 -1386 145 -3295 20 -544 26
            -715 70 -1905 63 -1692 122 -2939 180 -3810 11 -165 20 -315 20 -332 0 -29 3
            -33 26 -33 14 0 193 31 397 70 442 82 1117 208 1717 320 772 144 710 130 710
            154 0 12 -29 181 -65 376 -688 3786 -1094 7463 -1261 11425 -8 209 -16 381
            -17 381 -2 2 -841 17 -2982 54 -313 5 -715 13 -892 16 -184 4 -323 3 -323 -2z"/>
            </g>
        </svg>
    )
}

export const Shorts = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 -500 1280 2000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1100.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M2020 10688 c0 -322 -4 -390 -40 -733 -23 -222 -38 -302 -81 -452
            -44 -154 -109 -306 -215 -504 -94 -176 -289 -580 -324 -669 -9 -25 -49 -124
            -88 -220 -142 -353 -249 -681 -337 -1030 -91 -365 -126 -566 -210 -1200 -99
            -741 -247 -1590 -416 -2385 -75 -351 -106 -480 -214 -885 l-86 -325 -4 -235
            -4 -235 66 -190 66 -190 121 -171 c129 -182 146 -199 391 -379 139 -102 409
            -226 780 -357 697 -247 1264 -378 2000 -463 121 -14 287 -35 369 -46 142 -19
            157 -19 330 -5 100 8 262 18 361 21 205 6 401 25 625 58 l155 24 26 84 c15 46
            63 203 108 349 44 146 125 411 180 590 278 911 379 1246 379 1260 -1 8 36 134
            80 280 88 288 276 910 352 1165 27 88 51 153 53 145 3 -8 33 -118 67 -245 34
            -126 95 -351 135 -500 41 -148 133 -488 205 -755 72 -267 167 -618 211 -780
            173 -636 231 -850 335 -1232 l107 -398 31 -4 c77 -11 360 -28 628 -36 518 -17
            1356 23 1998 96 528 59 1050 202 1626 444 144 61 609 292 682 340 287 185 384
            490 303 955 -10 61 -37 214 -59 340 -22 127 -52 309 -66 405 -14 96 -52 348
            -85 560 -33 212 -99 639 -146 950 -136 892 -244 1546 -335 2015 -10 55 -28
            147 -39 205 -125 657 -126 664 -282 1195 -111 375 -339 948 -672 1686 -64 140
            -127 289 -141 330 -62 187 -91 427 -106 880 -9 306 -14 399 -26 507 l-6 52
            -4394 0 -4394 0 0 -312z"/>
            </g>
        </svg>
    )
}

export const Skirt = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 -500 935 2000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M4310 12789 c-998 -40 -2003 -197 -2890 -450 -266 -76 -430 -130
            -430 -140 0 -6 12 -52 27 -102 27 -88 28 -102 27 -292 0 -110 -6 -235 -12
            -278 -7 -43 -12 -169 -12 -280 -1 -797 -62 -1740 -170 -2607 -67 -534 -102
            -1103 -160 -2560 -42 -1054 -82 -1616 -155 -2190 -97 -754 -267 -1426 -493
            -1947 -46 -107 -43 -132 21 -165 23 -11 204 -111 402 -221 856 -475 1251 -648
            1677 -734 126 -25 133 -25 182 -10 268 84 594 71 1071 -43 304 -73 466 -123
            1475 -455 627 -206 694 -225 960 -264 252 -37 396 -46 740 -45 388 0 704 23
            1090 79 58 8 247 22 420 30 339 16 432 26 580 57 231 49 444 160 614 319 36
            33 68 68 71 78 3 10 -24 206 -60 437 -216 1386 -218 1401 -236 2627 -9 641 -8
            740 5 820 129 771 138 2542 20 3872 -138 1562 -431 2715 -849 3345 -35 52
            -111 151 -170 220 -151 177 -200 267 -260 480 -15 52 -30 98 -34 102 -11 12
            -437 99 -726 148 -874 147 -1817 206 -2725 169z"/>
            </g>
        </svg>
    )
}

export const Dress = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 884.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M5017 12468 c-4 -255 -9 -338 -22 -393 -9 -38 -19 -80 -22 -93 -4
            -17 -2 -21 11 -16 27 10 19 -15 -38 -115 -30 -53 -71 -139 -92 -191 -21 -52
            -96 -212 -168 -355 -124 -245 -152 -311 -176 -412 -6 -23 -23 -56 -37 -74
            l-27 -31 32 7 c39 8 43 -11 9 -43 -45 -43 -100 -22 -112 42 -4 18 -20 48 -37
            66 -26 29 -29 39 -24 70 5 32 3 38 -19 48 -16 7 -28 25 -35 53 -13 46 -46 116
            -138 291 -34 65 -62 128 -62 141 0 16 -9 26 -28 33 -23 9 -32 23 -46 65 -19
            57 -122 260 -188 368 -22 35 -44 75 -49 88 -22 59 -76 417 -95 625 -8 96 -21
            111 -97 111 -59 0 -96 -18 -117 -58 -9 -17 -22 -25 -39 -25 -30 0 -110 -37
            -226 -104 -44 -26 -102 -53 -128 -61 -61 -18 -100 -42 -205 -123 -93 -72 -252
            -220 -284 -265 -12 -16 -25 -48 -29 -71 -5 -22 -15 -257 -24 -521 -16 -529
            -30 -730 -52 -790 -30 -81 -43 -175 -51 -375 -14 -310 -8 -340 92 -475 75
            -100 113 -172 132 -246 20 -80 43 -126 104 -209 77 -106 77 -105 40 -157 l-31
            -45 34 21 c44 27 68 27 96 -3 70 -74 77 -326 15 -514 -26 -80 -51 -122 -113
            -197 -23 -27 -51 -69 -62 -93 -20 -40 -24 -43 -51 -37 -25 6 -30 3 -35 -15 -5
            -18 -11 -21 -37 -16 -31 6 -32 5 -28 -26 3 -31 -8 -45 -146 -189 -160 -167
            -196 -214 -218 -289 -16 -55 -45 -87 -109 -120 -57 -29 -95 -64 -95 -86 0 -14
            4 -13 35 5 58 34 40 7 -56 -82 -88 -83 -90 -85 -84 -121 8 -39 7 -40 -58 -51
            l-38 -6 25 -20 c47 -37 25 -129 -30 -129 -27 0 -28 3 -14 31 8 15 7 19 -4 19
            -21 0 -45 -37 -39 -60 4 -14 -2 -22 -21 -30 -27 -10 -44 -35 -31 -48 3 -4 29
            6 57 21 l51 29 -7 -33 c-11 -58 -69 -163 -101 -184 -24 -15 -31 -27 -31 -50 0
            -17 -25 -85 -57 -151 -31 -65 -59 -132 -62 -149 -15 -66 -15 -67 -45 -62 -27
            6 -28 5 -23 -33 8 -57 -7 -107 -117 -385 -96 -243 -167 -472 -324 -1045 -71
            -260 -83 -308 -106 -420 -12 -58 -53 -193 -91 -300 -77 -215 -126 -376 -158
            -520 -11 -52 -37 -167 -56 -255 -19 -88 -60 -241 -90 -340 -90 -291 -123 -426
            -166 -685 -22 -135 -49 -281 -60 -325 -27 -109 -158 -374 -209 -424 -37 -36
            -38 -39 -61 -180 -21 -128 -22 -145 -8 -169 37 -66 128 -99 319 -113 204 -15
            362 -59 438 -123 25 -22 49 -30 110 -38 47 -7 82 -17 89 -26 9 -11 13 -54 13
            -132 l0 -117 257 1 c243 1 260 0 293 -20 45 -26 76 -72 89 -134 6 -27 17 -136
            26 -242 8 -106 18 -216 21 -244 l5 -51 192 -7 c294 -11 434 -43 541 -125 73
            -54 114 -65 241 -65 87 0 122 5 165 21 30 12 75 28 99 38 106 39 342 45 382 9
            15 -13 19 -31 19 -81 l0 -64 228 6 c609 17 739 49 758 190 10 78 14 86 46 99
            l31 13 -32 19 c-34 20 -39 39 -16 71 29 41 101 54 355 60 295 7 548 -8 594
            -36 17 -10 41 -22 54 -26 31 -9 29 18 -3 33 -36 17 -31 31 8 23 53 -13 105
            -41 123 -68 9 -15 19 -58 23 -105 9 -109 8 -108 193 -108 l148 0 0 130 0 130
            103 0 c140 0 280 -16 355 -40 35 -11 132 -52 216 -90 268 -121 338 -132 846
            -134 398 -1 405 0 493 57 49 34 61 52 62 102 0 25 4 31 28 33 35 4 34 13 -3
            36 -34 20 -31 36 6 36 40 0 60 16 80 62 15 37 18 74 18 278 1 213 3 238 20
            263 16 24 18 43 15 170 l-4 142 75 3 c50 2 80 8 92 18 12 12 27 14 59 9 54 -9
            59 0 52 91 -3 39 -3 83 1 97 6 27 33 40 176 87 112 36 313 110 322 119 23 20
            -85 493 -222 973 -87 303 -106 360 -167 482 -52 104 -66 160 -52 216 2 8 -1
            14 -8 13 -18 -4 -47 39 -76 109 -144 352 -197 502 -197 564 0 16 -23 74 -50
            129 -37 74 -64 151 -104 300 -56 206 -124 392 -286 785 -51 124 -102 247 -112
            274 l-19 48 25 -6 c34 -8 40 7 15 39 -15 18 -28 25 -41 21 -18 -4 -19 -1 -14
            30 6 29 3 39 -20 62 -46 48 -124 261 -124 341 0 23 -14 58 -39 100 -22 36 -70
            134 -106 216 -90 204 -273 570 -337 678 -76 126 -278 412 -348 493 -134 154
            -137 159 -170 264 -26 85 -39 110 -84 163 -121 143 -200 267 -220 345 -11 40
            -16 47 -46 55 -55 14 -127 73 -153 125 -12 25 -29 48 -36 50 -10 3 -11 0 -1
            -18 13 -25 2 -44 -22 -35 -49 19 -78 203 -60 382 22 221 75 363 193 518 69 91
            94 135 103 185 3 19 48 118 99 220 154 307 257 660 277 950 5 69 16 159 26
            200 23 102 28 160 15 160 -18 0 -32 38 -41 119 -16 138 -128 448 -251 694
            -110 220 -156 268 -342 361 -73 36 -181 83 -241 106 -146 54 -386 179 -482
            251 -44 32 -82 59 -85 59 -4 0 -9 -145 -12 -322z"/>
            </g>
        </svg>
    )
}

export const Sneakers = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="-250 -700 1700 2000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,858.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M7100 8549 c-56 -135 -260 -344 -496 -507 l-61 -43 -58 36 c-70 42
            -113 55 -180 55 -136 0 -429 -130 -470 -209 -22 -43 -19 -59 15 -93 21 -21 28
            -34 21 -42 -5 -6 -11 -31 -13 -54 l-3 -43 -146 -79 c-138 -74 -148 -78 -185
            -69 -69 15 -109 11 -137 -15 -24 -23 -32 -39 -39 -91 -2 -15 -58 -49 -214
            -133 l-211 -112 -91 13 c-50 8 -105 12 -122 9 -44 -6 -75 -50 -91 -127 l-13
            -66 -253 -135 -254 -136 -108 7 -108 7 -32 -28 c-23 -22 -34 -44 -44 -89 l-12
            -60 -334 -179 c-297 -159 -341 -180 -395 -185 -78 -9 -100 -21 -120 -70 -8
            -22 -26 -45 -38 -52 -13 -7 -111 -69 -218 -138 -237 -153 -392 -234 -485 -251
            -80 -15 -159 -10 -520 31 -143 16 -325 32 -405 36 -195 8 -269 -5 -385 -63
            -414 -210 -497 -302 -565 -626 -18 -85 -26 -109 -42 -114 -43 -13 -130 -150
            -191 -300 -52 -129 -97 -349 -97 -475 0 -46 2 -49 73 -114 101 -95 257 -208
            408 -299 438 -264 1136 -555 1614 -675 188 -47 621 -141 785 -171 182 -33 169
            -28 132 -50 -121 -74 -281 -423 -388 -842 -33 -131 -34 -143 -25 -216 6 -42
            13 -160 16 -261 9 -316 35 -451 119 -626 65 -132 142 -213 260 -270 246 -118
            952 -326 1480 -435 578 -119 1023 -171 1611 -190 1301 -41 1927 41 2773 364
            306 117 523 218 642 299 65 45 165 143 199 196 36 56 82 168 112 276 31 106
            34 312 6 362 -17 31 -16 33 4 65 l21 33 -7 -40 c-4 -24 -3 -36 2 -30 5 6 46
            54 92 107 159 188 278 281 463 364 120 54 126 55 248 64 94 6 149 16 221 38
            473 149 1278 525 1636 764 272 182 298 240 298 658 0 269 -28 600 -76 925
            l-12 80 -17 -50 c-22 -63 -18 -80 -74 310 -67 461 -182 1252 -217 1485 -57
            385 -104 525 -276 814 -81 137 -105 189 -139 308 -64 226 -136 287 -409 348
            -124 27 -219 57 -545 173 -161 56 -357 48 -579 -26 l-94 -31 -16 24 c-9 13
            -41 50 -72 81 -136 138 -527 266 -703 229 -43 -9 -71 -23 -113 -57 -66 -54
            -260 -181 -431 -283 l-123 -73 -217 6 c-242 7 -313 -2 -412 -50 -73 -36 -196
            -160 -245 -245 -39 -68 -53 -78 -110 -78 -17 0 -62 -9 -100 -19 -67 -19 -70
            -19 -104 -1 -20 10 -48 31 -63 47 -98 104 -121 143 -138 231 -8 45 -7 48 39
            107 111 140 166 259 165 360 -1 144 -83 214 -419 360 -71 31 -134 61 -139 66
            -19 18 -44 9 -56 -22z m-718 -620 c25 -13 18 -22 -48 -59 -47 -28 -61 -31
            -102 -26 -51 6 -111 24 -125 36 -9 8 76 43 142 60 40 10 104 5 133 -11z m5763
            -4134 c-39 -32 -153 -112 -140 -97 13 14 159 121 165 121 3 0 -8 -11 -25 -24z"/>
            </g>
        </svg>
    )
}

export const Boots = ({fill}) => {
    return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width={width} height={height} viewBox="-350 -400 2000 2000"
                preserveAspectRatio="xMidYMid meet">
                <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
                </metadata>
                <g transform="translate(0.000000,1269.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
                <path d="M7334 12676 c-173 -33 -327 -100 -437 -191 -152 -126 -227 -270 -227
                -436 0 -100 14 -165 56 -259 18 -41 39 -111 48 -155 66 -352 82 -594 106
                -1545 14 -553 36 -806 99 -1112 18 -87 19 -102 6 -201 -131 -991 -217 -2323
                -265 -4072 -27 -1007 -32 -1108 -56 -1147 -11 -18 -44 26 -111 150 -50 92 -86
                144 -136 196 -57 60 -70 81 -86 136 -38 128 -57 232 -107 578 -54 375 -97 553
                -187 767 -86 206 -98 255 -98 415 0 180 36 316 172 657 l69 172 -51 103 c-60
                124 -111 284 -138 433 -19 104 -26 162 -67 560 -22 213 -56 396 -99 532 l-35
                112 15 93 c49 305 50 331 50 1298 1 949 4 1029 46 1325 32 228 74 398 123 505
                66 145 85 250 67 371 -47 299 -313 550 -664 624 -85 19 -254 19 -329 1 l-58
                -13 0 28 c0 69 -51 39 -124 -74 -154 -236 -306 -643 -306 -817 l0 -49 -42 -6
                c-87 -11 -721 -65 -943 -80 -472 -31 -954 -12 -1254 49 l-63 13 6 -51 c4 -28
                10 -68 13 -88 3 -21 1 -38 -4 -38 -5 0 -40 64 -78 142 -143 292 -233 391 -374
                414 -56 9 -92 -25 -98 -92 -10 -97 48 -294 142 -488 l44 -90 -57 -61 c-135
                -144 -242 -357 -306 -610 -32 -128 -66 -319 -111 -620 -20 -132 -47 -294 -60
                -360 -41 -206 -115 -905 -130 -1225 -3 -74 -10 -154 -15 -176 -5 -23 -21 -160
                -35 -305 -15 -145 -33 -300 -41 -344 -8 -44 -19 -120 -23 -170 -12 -123 -49
                -367 -67 -440 -39 -160 -42 -240 -7 -233 25 5 41 65 42 154 1 45 12 142 26
                215 63 335 106 667 165 1279 70 737 90 911 115 1014 14 55 30 140 36 190 17
                145 40 275 50 282 11 6 11 -43 -1 -111 -4 -27 -6 -52 -4 -54 12 -13 37 93 64
                275 49 336 70 451 105 589 56 221 122 365 210 462 48 54 118 104 129 93 2 -3
                47 -133 99 -290 181 -539 346 -1122 521 -1835 91 -368 111 -441 171 -613 51
                -147 57 -171 56 -240 -1 -102 25 -158 116 -250 144 -145 389 -272 525 -272 58
                0 69 16 45 65 -17 37 -70 76 -164 123 -34 18 -64 36 -67 41 -6 10 41 61 57 61
                16 0 1 74 -42 215 -22 72 -36 131 -31 133 15 5 12 22 -4 22 -19 0 -33 97 -16
                113 14 14 40 -16 40 -45 0 -33 41 -171 81 -273 78 -201 209 -369 335 -431 53
                -26 69 -29 155 -29 109 0 121 5 359 144 411 241 665 477 751 699 31 80 32 206
                1 282 -34 86 -88 149 -157 184 -78 40 -80 61 -5 61 112 -1 248 -94 315 -216
                24 -45 29 -67 33 -151 4 -88 2 -108 -23 -183 -15 -48 -65 -153 -112 -238 -47
                -83 -99 -185 -115 -226 -40 -98 -48 -205 -23 -296 18 -70 25 -79 68 -89 16 -4
                40 -18 54 -32 23 -23 25 -31 21 -91 -4 -56 -12 -81 -68 -186 -120 -227 -159
                -312 -165 -354 -6 -36 -3 -44 17 -60 66 -51 116 -113 133 -163 33 -100 7 -238
                -67 -347 l-28 -42 25 -27 c14 -15 30 -44 37 -65 15 -53 -4 -100 -92 -224 -39
                -53 -70 -101 -70 -105 0 -9 -87 -47 -115 -50 -73 -8 -62 37 54 230 28 47 51
                89 51 93 0 4 -33 -21 -74 -57 -83 -72 -187 -126 -348 -180 -112 -37 -225 -62
                -235 -52 -11 11 31 47 102 88 43 24 59 37 42 33 -133 -29 -93 147 70 309 96
                95 218 141 269 100 18 -15 18 -15 -3 -5 -37 18 -71 12 -107 -19 -34 -31 -92
                -117 -83 -126 2 -3 18 -6 36 -7 35 -2 34 0 21 -47 -19 -65 11 -93 48 -45 11
                14 24 25 30 25 5 0 22 10 37 21 21 17 26 28 22 48 -5 23 1 30 55 61 124 72
                228 221 228 327 0 44 -5 58 -31 87 -16 19 -40 38 -51 42 -20 6 -19 7 8 29 40
                32 29 37 -33 15 -120 -42 -249 -141 -301 -229 -65 -113 -341 -310 -587 -420
                -61 -27 -171 -70 -245 -95 -74 -25 -172 -58 -216 -75 -212 -78 -432 -86 -689
                -26 -26 6 -25 4 16 -23 123 -80 119 -199 -10 -372 -23 -30 -46 -72 -52 -95 -5
                -22 -24 -85 -41 -140 -101 -334 -312 -723 -592 -1095 l-87 -115 -182 -5 c-133
                -4 -199 -10 -247 -24 -71 -20 -173 -24 -455 -17 -193 5 -249 -6 -300 -59 -16
                -17 -38 -31 -49 -31 -17 -1 -18 -2 -3 -6 20 -5 20 -4 7 -39 -6 -15 -12 -64
                -14 -108 -3 -65 -13 -112 -56 -241 -61 -185 -71 -275 -35 -315 17 -19 28 -22
                80 -18 l60 4 -79 -76 c-159 -151 -297 -337 -378 -507 -46 -97 -88 -237 -88
                -294 0 -15 -44 -117 -99 -227 -109 -224 -150 -334 -158 -431 -18 -220 131
                -428 423 -587 214 -117 637 -280 964 -373 312 -88 474 -115 858 -141 756 -52
                1100 -44 1427 34 170 41 631 174 820 237 213 70 403 160 460 217 107 105 172
                212 243 402 39 106 71 172 94 200 71 84 171 81 316 -11 25 -16 57 -29 71 -29
                40 -1 104 -34 133 -68 40 -48 63 -122 92 -299 36 -219 79 -319 164 -378 225
                -156 558 -338 847 -463 658 -285 1467 -457 2340 -497 72 -3 202 -10 290 -15
                526 -32 595 -33 595 -10 0 20 -152 58 -400 100 -102 17 -220 40 -263 51 -66
                17 -107 19 -260 18 -167 -2 -186 0 -229 20 -56 26 -163 35 -273 24 -114 -12
                -184 -1 -315 50 -158 61 -301 103 -675 196 -488 122 -697 189 -870 281 -164
                86 -399 222 -470 271 -75 52 -282 256 -334 328 -36 51 -40 95 -12 111 59 31
                220 -14 339 -96 106 -73 229 -131 327 -155 75 -17 119 -19 436 -19 195 0 383
                -1 419 -2 36 -2 128 3 205 11 366 37 860 -1 1380 -104 248 -50 408 -66 702
                -72 285 -6 487 3 908 43 285 26 338 38 795 173 113 34 313 93 445 132 693 203
                750 227 791 334 24 65 9 175 -40 284 -41 89 -40 108 1 141 14 11 24 22 22 23
                -2 2 -31 15 -64 29 -154 67 -305 60 -685 -32 -213 -52 -338 -67 -652 -80 -141
                -5 -301 -16 -355 -25 -107 -16 -267 -55 -325 -80 -21 -8 -38 -11 -38 -6 0 14
                212 81 405 130 312 78 657 141 774 141 45 0 52 2 42 14 -14 17 -1 20 214 41
                185 19 260 32 300 52 37 19 46 33 39 56 -3 10 1 26 9 35 78 89 89 297 28 490
                -25 78 -76 180 -106 212 -31 34 -82 116 -141 229 -60 114 -109 161 -192 185
                -120 33 -124 20 -42 -140 35 -68 60 -127 56 -131 -5 -4 -11 -3 -13 4 -12 34
                -52 80 -83 95 -45 23 -94 22 -147 -1 -54 -25 -76 -66 -71 -134 3 -50 2 -51
                -29 -58 -48 -10 -95 -41 -117 -79 -15 -25 -21 -59 -25 -140 -9 -154 -20 -167
                -191 -214 -58 -16 -120 -26 -160 -26 -119 1 -176 43 -323 242 -46 62 -100 127
                -120 145 -66 57 -140 64 -252 22 -65 -24 -67 -24 -65 8 6 86 -24 137 -86 149
                -30 5 -140 -14 -191 -33 -23 -9 -14 12 22 54 24 28 32 44 24 49 -16 10 -4 94
                20 143 12 22 39 59 61 82 35 37 43 41 73 36 26 -4 51 3 108 31 128 64 96 90
                -95 79 -100 -5 -114 4 -23 17 35 6 52 14 64 33 16 24 16 26 -5 43 -22 18 -67
                25 -257 36 -60 3 -117 10 -125 14 -8 5 -38 4 -66 -1 -70 -13 -123 5 -241 82
                -110 71 -169 96 -248 105 -30 4 -55 8 -55 9 0 2 7 16 15 31 11 22 15 66 15
                181 0 218 24 313 78 313 36 0 69 -22 179 -117 114 -100 173 -143 243 -178 30
                -15 69 -40 86 -55 73 -64 291 -128 511 -151 208 -21 433 0 433 41 0 20 -51 59
                -104 79 -286 108 -806 449 -942 617 -21 27 -62 77 -91 111 -28 34 -57 71 -63
                83 -11 20 -9 21 28 17 29 -2 54 -15 90 -45 65 -55 152 -107 220 -132 68 -26
                90 -13 35 21 -32 20 -35 24 -18 27 33 6 38 19 16 41 -31 31 -51 27 -51 -9 l0
                -30 -46 22 c-35 18 -42 25 -31 33 80 59 25 218 -101 293 -44 25 -55 27 -157
                27 -131 -1 -195 -11 -466 -76 -113 -27 -244 -55 -290 -63 -46 -8 -115 -26
                -154 -40 -77 -28 -211 -36 -233 -14 -18 18 3 45 62 79 35 19 56 38 60 54 10
                40 54 82 104 100 31 11 102 20 209 25 239 12 262 31 208 165 -6 14 2 10 26
                -13 73 -71 167 -100 270 -82 146 26 163 25 221 -10 31 -20 67 -33 88 -34 31
                -1 35 2 38 27 7 58 -72 122 -123 99 -25 -11 -25 -11 -25 36 0 67 -34 131 -85
                159 -37 21 -53 22 -214 22 -196 1 -243 -7 -502 -82 -182 -53 -206 -58 -291
                -61 -49 -2 -80 -10 -143 -40 -91 -43 -90 -41 20 37 86 61 114 92 96 110 -6 6
                -43 12 -83 15 -87 5 -173 31 -173 52 0 22 88 24 220 6 120 -17 255 -21 380
                -12 161 11 382 92 522 192 64 46 64 46 49 16 -34 -65 -10 -138 53 -168 30 -15
                43 -15 96 -5 71 14 108 5 189 -47 28 -18 56 -32 64 -32 8 0 27 14 43 31 21 22
                26 35 20 47 -4 10 -11 44 -14 78 -5 67 -22 107 -68 159 l-31 35 -39 -20 c-21
                -11 -59 -23 -84 -27 -41 -5 -45 -4 -45 15 0 13 17 34 45 56 l46 34 -68 7 c-37
                4 -88 11 -113 17 -25 5 -67 13 -95 18 -115 19 -342 88 -420 127 -16 9 -76 44
                -131 79 -289 180 -615 354 -666 354 -28 0 -39 -22 -18 -35 6 -3 10 -10 10 -15
                0 -12 -167 35 -275 77 -307 121 -538 282 -608 422 -53 107 -28 218 69 311 37
                36 43 39 26 14 -41 -60 -57 -116 -57 -199 0 -71 4 -89 33 -147 38 -78 101
                -139 180 -176 48 -22 77 -27 182 -33 141 -7 163 -13 397 -105 227 -89 320
                -104 418 -68 16 6 18 5 8 -7 -21 -22 -16 -73 10 -97 41 -39 97 -55 207 -59
                149 -7 291 16 361 58 104 62 192 99 234 99 56 0 73 -12 248 -169 l29 -26 -27
                60 c-61 133 -67 200 -22 229 12 9 101 29 197 45 218 37 250 47 325 100 34 25
                49 40 35 35 -14 -4 -68 -20 -120 -36 -60 -19 -126 -31 -180 -34 -47 -3 -118
                -12 -157 -21 -122 -26 -299 -14 -528 36 -204 45 -427 111 -460 135 -28 21 -29
                21 285 -23 192 -28 290 -46 324 -61 54 -24 120 -29 153 -11 12 6 37 37 56 69
                37 63 62 75 92 42 l18 -20 56 31 c64 36 101 67 101 85 0 7 -10 19 -22 26 -30
                16 -256 16 -455 -2 -285 -25 -477 -1 -690 85 -421 169 -837 628 -943 1040 -34
                132 -47 271 -35 380 6 60 11 73 41 103 65 65 92 22 94 -148 1 -204 19 -412 40
                -489 26 -93 42 -123 94 -180 23 -25 64 -83 91 -129 55 -92 116 -159 154 -169
                42 -10 90 66 91 147 1 17 9 4 25 -39 30 -82 69 -130 132 -162 l51 -26 -34 -16
                c-44 -21 -44 -48 0 -82 58 -45 228 -101 356 -119 25 -4 72 -20 105 -36 150
                -74 428 -89 652 -35 113 28 230 90 295 156 53 54 62 60 129 74 76 15 123 39
                117 58 -2 7 -29 25 -61 41 -45 22 -60 26 -71 17 -10 -8 -16 -9 -21 -1 -3 5 -3
                10 2 10 4 1 -6 7 -23 14 -42 19 -164 99 -164 108 0 5 12 7 28 6 155 -10 193
                39 165 215 -20 117 -12 176 27 220 27 30 32 32 97 31 52 -1 91 -10 168 -39
                319 -119 378 -134 390 -95 21 67 -33 97 -325 176 -184 50 -281 91 -297 127
                -12 27 -7 42 39 98 14 19 63 96 107 172 95 164 130 209 173 230 45 21 80 12
                123 -32 74 -73 133 -193 176 -358 11 -45 27 -90 36 -100 24 -29 10 125 -22
                232 -66 229 -174 372 -302 400 -32 6 -33 8 -27 48 18 116 99 327 291 752 166
                368 191 429 260 625 88 248 152 358 446 754 85 115 176 245 203 289 55 94 103
                209 104 252 0 27 -4 24 -47 -38 -48 -70 -67 -82 -37 -26 53 102 80 249 52 277
                -24 24 -79 13 -157 -32 -148 -86 -330 -148 -541 -182 -125 -21 -1456 -38
                -1745 -23 -258 13 -880 73 -1001 95 -39 7 -99 55 -121 97 -9 18 -33 95 -53
                172 -20 77 -40 148 -44 158 -6 13 -5 16 8 11 9 -3 16 -1 16 5 0 6 -8 11 -17
                11 -11 0 -28 20 -46 53 -64 122 -145 184 -299 227 -94 27 -284 35 -384 16z
                m-2114 -656 c11 -7 9 -11 -13 -19 -16 -6 -40 -11 -54 -11 -15 0 -36 -12 -55
                -32 -24 -26 -31 -29 -39 -17 -15 25 -10 57 13 73 25 18 123 22 148 6z m2505
                -21 c8 -24 -8 -39 -42 -39 -27 0 -29 16 -6 42 21 24 40 23 48 -3z m-5760 -88
                c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m3260 -21 c-6 -9 -9 -9
                -16 1 -10 17 0 34 13 21 6 -6 7 -16 3 -22z m-105 -50 c0 -52 -13 -67 -24 -27
                -11 41 -7 77 9 77 11 0 15 -12 15 -50z m3096 -210 c22 -111 27 -174 31 -350 2
                -136 1 -209 -5 -200 -10 16 -27 134 -38 268 -4 45 -11 82 -15 82 -5 0 -9 5 -9
                11 0 5 5 7 10 4 17 -10 11 120 -10 250 -11 66 -20 127 -19 135 1 34 31 -74 55
                -200z m-2966 155 c0 -35 -87 -91 -108 -70 -9 9 73 85 93 85 8 0 15 -7 15 -15z
                m2315 -259 c-9 -77 -47 -346 -85 -596 -38 -250 -74 -500 -80 -555 -21 -192
                -49 -302 -50 -197 0 67 29 274 41 286 4 5 6 34 3 65 -4 32 1 94 10 146 8 50
                22 169 31 265 37 422 57 554 108 720 l32 105 3 -49 c2 -26 -4 -112 -13 -190z
                m4030 144 c-10 -11 -20 -18 -23 -15 -7 6 18 35 31 35 5 0 2 -9 -8 -20z m-3418
                -82 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-6138 -155 c32 -48
                37 -63 23 -63 -7 0 -56 79 -71 114 -9 23 20 -9 48 -51z m2375 -154 c53 -51
                102 -101 108 -113 12 -23 -98 72 -177 153 -84 86 -33 56 69 -40z m647 48 c-15
                -18 -111 -70 -118 -64 -2 3 6 12 19 20 93 57 120 69 99 44z m4409 -37 c24 -8
                24 -8 -5 -8 -16 0 -43 4 -60 8 l-30 8 35 0 c19 0 46 -4 60 -8z m-6946 -106
                c26 -40 59 -107 72 -149 32 -102 125 -448 163 -610 161 -684 230 -936 355
                -1294 20 -57 35 -106 32 -108 -7 -7 -53 76 -84 152 -43 104 -81 226 -181 570
                -49 171 -110 369 -136 440 -25 72 -55 166 -65 210 -24 104 -37 152 -122 429
                -73 239 -118 406 -118 437 0 32 31 4 84 -77z m7109 69 c-13 -2 -35 -2 -50 0
                -16 2 -5 4 22 4 28 0 40 -2 28 -4z m-1925 -14 c-2 -6 -8 -10 -13 -10 -5 0 -11
                4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m2 -100 c0 -5 -4 -9 -10 -9 -5 0
                -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m93 -17 c21 -23 13 -32 -30
                -32 -36 0 -56 25 -32 41 22 14 45 11 62 -9z m-2696 -72 c5 -26 -11 -38 -59
                -45 -41 -6 -48 -5 -48 9 0 20 58 57 85 54 11 -2 21 -10 22 -18z m3167 -240 c4
                -68 3 -84 -4 -55 -4 22 -11 83 -14 135 -4 69 -3 84 4 55 5 -22 11 -83 14 -135z
                m-6014 -20 c0 -11 -4 -20 -10 -20 -5 0 -10 2 -10 4 0 2 -3 11 -6 20 -4 11 -1
                16 10 16 9 0 16 -8 16 -20z m4 -65 c3 -9 0 -15 -9 -15 -8 0 -15 7 -15 15 0 8
                4 15 9 15 5 0 11 -7 15 -15z m6032 -70 c11 -27 -2 -37 -14 -11 -7 16 -8 26 -1
                26 5 0 12 -7 15 -15z m-36 -36 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7
                10 4 6 -3 10 -11 10 -16z m-5941 -106 c-15 -18 -34 -1 -25 22 5 14 9 15 21 5
                12 -10 13 -16 4 -27z m6011 -412 c18 -198 19 -252 10 -292 -7 -27 -16 -49 -20
                -49 -16 0 -20 64 -24 410 -5 377 -4 375 34 -69z m-391 330 c-10 -52 -18 -67
                -48 -91 -69 -57 -76 -26 -19 80 60 110 88 114 67 11z m-2879 -72 c0 -29 -42
                -77 -71 -83 -64 -13 -60 18 9 64 49 32 62 36 62 19z m2880 -58 c0 -6 -4 -13
                -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-6360 -42 c0 -5
                -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m923 -201
                c-3 -35 -10 -72 -14 -83 -8 -20 -3 76 7 123 9 45 14 20 7 -40z m4848 26 c10
                -29 3 -68 -12 -59 -9 6 -13 85 -4 85 3 0 10 -12 16 -26z m-4599 -29 c0 -5 -5
                -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-1197 -36
                c11 -17 2 -18 -19 -3 -17 12 -17 14 -4 14 9 0 20 -5 23 -11z m1219 -48 c-7 -7
                -34 28 -34 43 1 6 10 0 21 -13 11 -13 17 -27 13 -30z m4576 -12 c0 -6 -4 -7
                -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m3317 -106 c-3
                -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-8090 10 c5 -13 -43 -30
                -54 -19 -3 3 0 13 7 21 15 18 41 16 47 -2z m2268 -31 c-26 -40 -85 -82 -85
                -61 0 15 84 99 99 99 6 0 0 -17 -14 -38z m2475 28 c0 -5 -2 -10 -4 -10 -3 0
                -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-5 -110 c3 -5 1 -10 -4 -10
                -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m512 -32 c-3 -7 -5 -2 -5
                12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-3040 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17
                5 13 3 -3 4 -12 1 -19z m3038 -38 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0
                13 5 20 13 18 6 -3 12 -11 12 -18z m546 -74 c6 -39 14 -113 18 -164 l6 -94 49
                -20 c27 -10 166 -53 310 -94 370 -107 523 -168 671 -266 113 -75 198 -173 167
                -191 -16 -11 -87 25 -292 148 -320 191 -486 265 -642 283 -86 10 -178 -1 -195
                -24 -30 -41 -63 -247 -63 -402 0 -203 50 -361 149 -463 28 -29 51 -53 51 -54
                0 0 -15 -2 -32 -3 -50 -4 -102 -44 -130 -100 l-25 -50 -6 142 c-4 80 -11 142
                -17 144 -5 2 -10 10 -10 18 0 9 3 13 8 11 4 -3 4 63 1 146 -4 84 -10 312 -14
                507 -4 208 -12 400 -21 463 -8 59 -14 120 -14 135 0 46 18 5 31 -72z m-3381
                70 c0 -52 -41 -96 -90 -96 -30 0 -24 16 25 65 46 46 65 55 65 31z m2920 -26
                c12 -8 12 -10 -3 -16 -22 -8 -50 3 -42 16 8 12 25 13 45 0z m2510 0 c0 -5 -4
                -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-3130 -88 c0
                -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6 -3 10 -14 10 -23z m-23 -84 c-3 -8 -6
                -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-4546 -68 c-9 -23 -10 -23 -10 11
                -1 20 2 39 7 43 11 12 14 -27 3 -54z m5616 38 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
                13 3 -3 4 -12 1 -19z m10 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
                -19z m2003 -329 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z
                m-2340 -24 c0 -14 -2 -25 -4 -25 -2 0 -6 11 -8 25 -3 14 -1 25 3 25 5 0 9 -11
                9 -25z m-4439 -25 c-22 -19 -41 -26 -41 -15 0 5 51 34 60 34 3 0 -6 -9 -19
                -19z m14 -40 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7
                -4 4 -10z m6435 -21 c0 -6 -7 -6 -20 1 -11 6 -20 15 -20 21 0 6 7 6 20 -1 11
                -6 20 -15 20 -21z m-5120 1 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
                4 10 6 0 11 -4 11 -10z m-1395 -10 c14 -23 -29 -61 -120 -106 -52 -26 -97 -45
                -99 -43 -3 3 -1 22 4 43 15 69 80 115 162 116 26 0 50 -5 53 -10z m5595 -30
                c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
                m-4501 -113 c1 -30 -3 -46 -9 -42 -5 3 -10 36 -9 73 1 77 16 51 18 -31z
                m-1350 -112 c28 -111 29 -154 3 -123 -16 20 -42 132 -48 206 -7 92 7 65 45
                -83z m5862 122 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z
                m-5791 -47 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10 21 10 8 0 14
                -4 14 -10z m5716 -69 c-3 -5 5 -16 19 -25 14 -9 25 -21 25 -26 0 -18 -138 -5
                -158 15 -14 14 33 36 66 31 19 -3 30 -1 27 5 -4 5 1 9 10 9 9 0 14 -4 11 -9z
                m-66 -106 c7 -14 11 -28 7 -31 -3 -3 -13 8 -22 25 -8 17 -12 31 -7 31 5 0 15
                -11 22 -25z m165 15 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8
                0 12 -4 9 -10z m-55 -10 c0 -5 -19 -10 -42 -10 -32 0 -39 3 -28 10 20 13 70
                13 70 0z m885 -156 c-5 -48 -10 -64 -22 -64 -13 0 -15 9 -11 48 6 55 28 113
                35 94 3 -8 2 -42 -2 -78z m-5045 -5 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8
                8 24 -1 24 -14z m4802 -1 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
                -7 -17z m-1654 -109 c2 -23 2 -23 -13 -5 -18 24 -20 39 -2 34 6 -3 13 -15 15
                -29z m1863 -32 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1 -17
                -7 -23z m-1051 -27 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0
                16 -4 16 -10z m985 -29 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
                m-743 -88 c149 -52 198 -95 124 -109 -95 -17 -380 69 -438 133 -16 17 -12 18
                105 12 99 -5 139 -12 209 -36z m-1292 -29 c0 -8 -4 -14 -10 -14 -5 0 -10 9
                -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m-4468 4 c7 -18 -2 -39 -13 -32
                -12 7 -12 44 0 44 5 0 11 -6 13 -12z m-72 -38 c0 -5 -5 -10 -11 -10 -5 0 -7 5
                -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m78 -50 c3 -21 2 -22 -12 -11 -19 16
                -21 44 -3 39 6 -3 14 -15 15 -28z m4633 -170 c144 -187 266 -327 332 -380 62
                -48 67 -61 15 -34 -53 27 -105 70 -221 183 -101 99 -113 115 -180 241 -40 74
                -74 139 -76 145 -7 19 26 -20 130 -155z m1549 126 c0 -8 -4 -18 -10 -21 -5 -3
                -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-6243 -128 c-3 -8 -6 -5 -6
                6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m6639 -38 c26 -11 44 -23 41 -26 -3 -3
                -30 5 -59 19 -64 30 -50 36 18 7z m-460 -77 c-10 -10 -19 5 -10 18 6 11 8 11
                12 0 2 -7 1 -15 -2 -18z m-1596 -138 c0 -13 -64 -35 -102 -35 -61 0 -82 16
                -37 28 35 11 139 16 139 7z m-2675 -55 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
                0 6 2 10 4 10 3 0 8 -4 11 -10z m2608 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
                14 0 19 -2 13 -5z m-2568 -33 c5 -17 -9 -60 -20 -60 -3 0 -5 18 -5 40 0 42 14
                54 25 20z m-135 -99 c0 -11 -5 -23 -11 -27 -14 -8 -29 14 -22 33 8 20 33 16
                33 -6z m130 -7 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10
                -7 10 -16z m-160 -52 c0 -5 -9 -17 -20 -27 -22 -20 -26 -14 -10 16 11 19 30
                26 30 11z m4730 -80 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z
                m-1300 -179 c0 -37 -18 -101 -31 -108 -5 -4 -16 10 -24 29 -13 32 -14 40 -1
                71 19 45 56 50 56 8z m170 -52 c0 -4 -9 -20 -21 -35 -16 -20 -29 -26 -60 -26
                -49 0 -49 12 -2 45 37 24 83 34 83 16z m-3965 -391 c-9 -15 -41 -12 -55 5 -20
                25 -2 37 31 20 16 -9 27 -20 24 -25z m380 -19 c3 -5 1 -12 -5 -16 -5 -3 -10 1
                -10 9 0 18 6 21 15 7z m3479 -281 c4 -22 3 -31 -3 -25 -14 14 -47 104 -55 145
                -5 29 -1 25 23 -25 16 -33 32 -76 35 -95z m46 86 c0 -8 -4 -18 -10 -21 -5 -3
                -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-455 -40 c75 -32 35 -54
                -162 -92 -18 -4 -23 -11 -23 -38 0 -76 49 -160 119 -205 93 -60 255 -82 456
                -62 135 14 158 18 150 32 -4 5 -2 9 4 9 5 0 11 -5 13 -10 2 -7 50 -3 136 10
                l133 20 46 -20 c53 -23 49 -36 -13 -51 -22 -6 -187 -12 -369 -15 -181 -3 -340
                -7 -352 -10 -15 -3 -23 -12 -24 -27 0 -21 -1 -21 -8 -2 -5 11 -7 23 -5 26 2 4
                -15 2 -37 -4 -56 -14 -129 10 -206 67 -83 61 -153 178 -153 255 0 41 36 90 83
                111 51 24 162 26 212 6z m486 -78 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0
                15 9 10 21 -13z m-3509 -15 c25 -23 23 -34 -17 -73 -19 -18 -40 -50 -46 -69
                -15 -47 -64 -113 -134 -181 l-56 -55 26 75 c41 116 108 251 142 288 35 36 58
                40 85 15z m-1006 -100 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
                -18z m5174 -23 c0 -5 -13 -10 -30 -10 -16 0 -30 5 -30 10 0 6 14 10 30 10 17
                0 30 -4 30 -10z m-514 -60 c119 -31 126 -47 23 -56 -59 -5 -68 -3 -111 25 -85
                54 -51 66 88 31z m230 -5 c-11 -8 -25 -15 -30 -15 -6 1 0 7 14 15 32 19 40 18
                16 0z m-5121 -50 c23 -22 31 -55 27 -107 -2 -16 10 -18 120 -18 67 0 119 -2
                116 -5 -3 -3 -59 -19 -124 -35 -134 -33 -259 -74 -281 -92 -15 -12 -164 -40
                -172 -32 -3 2 1 10 7 16 7 7 12 23 12 36 0 36 -54 53 -202 61 -70 4 -133 12
                -139 18 -12 12 28 47 88 77 32 16 32 16 35 -12 3 -25 6 -27 48 -26 33 0 81 18
                185 66 153 72 162 75 218 77 28 1 44 -5 62 -24z m195 6 c0 -14 -18 -23 -30
                -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m2887 -36 c43 -30 58 -97 58 -260 -1
                -147 -7 -211 -46 -450 -24 -148 -79 -428 -85 -434 -11 -11 -7 212 12 604 8
                171 14 365 14 433 0 130 3 138 47 107z m-3888 -78 c6 -8 14 -27 17 -44 5 -26
                2 -31 -18 -36 -38 -11 -66 -8 -82 9 -21 20 -20 26 5 58 22 28 60 34 78 13z
                m6716 -27 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9
                -10z m-5195 -32 c6 -7 20 -28 30 -45 11 -18 27 -33 35 -33 38 0 -6 -144 -125
                -404 -217 -479 -227 -739 -55 -1435 33 -135 34 -145 19 -162 -35 -39 -67 -19
                -159 97 -37 47 -70 83 -72 80 -3 -3 -17 9 -31 27 -15 18 -79 88 -143 157 -64
                69 -130 145 -147 169 -31 42 -42 94 -23 105 4 3 47 10 94 15 109 13 155 25
                198 54 44 29 49 64 14 97 -14 13 -25 33 -25 45 0 37 -12 85 -21 85 -5 0 -9 -5
                -9 -11 0 -20 -71 -164 -93 -188 -50 -54 -123 -58 -179 -11 l-23 20 30 -15 c35
                -18 88 -19 117 -4 44 24 75 143 43 169 -13 11 -21 6 -50 -25 l-35 -37 -68 62
                c-127 118 -303 197 -484 220 -196 24 -601 -40 -793 -125 -99 -43 -72 -20 39
                34 145 71 261 114 413 153 122 31 136 33 313 33 166 0 193 -2 260 -24 109 -34
                147 -56 225 -132 81 -78 130 -104 170 -89 53 20 55 98 2 130 l-30 18 26 24
                c14 13 50 32 80 41 66 21 100 46 117 87 11 28 9 39 -21 118 l-34 87 -5 -91
                c-5 -89 -13 -111 -50 -141 -19 -15 -90 -17 -127 -2 -28 11 -28 11 37 40 35 16
                72 37 82 46 32 29 22 73 -39 170 -32 50 -66 105 -75 121 -20 37 -114 87 -238
                127 -74 24 -109 28 -270 35 -117 5 -195 12 -213 21 l-28 13 27 14 c39 21 180
                27 271 12 43 -6 177 -36 298 -66 330 -83 440 -83 515 -5 45 47 63 104 65 206
                l2 79 49 23 c56 26 78 28 94 11z m-296 -39 c5 -5 7 -11 4 -15 -7 -6 -68 23
                -68 32 0 8 53 -5 64 -17z m552 -4 c-10 -8 -23 -12 -28 -9 -14 9 2 24 25 24 21
                -1 21 -1 3 -15z m-796 -20 c-7 -8 -16 -15 -21 -15 -5 0 -9 7 -9 15 0 9 9 15
                21 15 18 0 19 -2 9 -15z m-1053 -51 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
                17 -2 13 -5z m1411 -38 c5 -45 -28 -165 -48 -176 -10 -6 -43 -10 -72 -10 -61
                0 -141 34 -146 63 -5 25 42 22 105 -8 l53 -24 10 42 c24 94 54 150 79 145 9
                -2 17 -15 19 -32z m-130 -54 c-6 -27 -23 -16 -26 18 -2 33 -2 34 13 15 9 -11
                14 -26 13 -33z m5287 8 c18 -20 18 -22 1 -38 -15 -15 -18 -15 -26 -2 -12 19
                -13 60 -2 60 5 0 17 -9 27 -20z m-76 -27 c24 -37 45 -103 32 -103 -3 0 -16 19
                -28 43 -48 92 -51 132 -4 60z m-4746 -15 c-5 -30 -46 -167 -61 -207 -5 -14 -4
                -14 11 -2 10 8 20 12 23 9 2 -3 -8 -12 -23 -19 -15 -8 -29 -21 -31 -29 -10
                -49 -53 -120 -81 -134 -20 -11 -32 -12 -36 -5 -15 23 27 136 93 254 25 44 56
                99 69 123 28 50 45 55 36 10z m4433 -15 c-11 -11 -17 4 -9 23 7 18 8 18 11 1
                2 -10 1 -21 -2 -24z m-6016 -47 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7
                10 15 10 8 0 15 -2 15 -4z m5710 0 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0
                16 -2 16 -4z m-5800 -40 c-16 -14 -70 -31 -70 -21 0 7 60 34 75 35 5 0 3 -6
                -5 -14z m5450 -16 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0
                15 -4 15 -10z m-4765 -92 c-16 -70 -75 -184 -83 -160 -8 26 81 252 95 239 3
                -3 -3 -39 -12 -79z m-849 31 c11 -18 -32 -44 -120 -74 -45 -15 -92 -34 -104
                -42 -54 -34 -28 26 31 71 61 47 175 73 193 45z m979 -93 c-36 -44 -55 -57 -55
                -36 0 14 79 94 86 87 3 -3 -11 -26 -31 -51z m60 5 c3 -6 -3 -15 -14 -21 -24
                -12 -42 7 -20 21 18 11 26 11 34 0z m795 -57 c0 -8 -4 -12 -10 -9 -5 3 -10 10
                -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-781 -40 c-37 -39 -84 -67 -101 -62
                -12 4 108 98 125 98 6 0 -5 -16 -24 -36z m781 -4 c0 -5 -5 -10 -11 -10 -5 0
                -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m5069 -24 c15 -8 44 -33 65 -56 47
                -52 46 -84 -1 -88 -21 -2 -33 -9 -33 -18 0 -27 -29 -14 -64 30 -28 33 -36 52
                -36 83 0 37 13 63 32 63 5 0 21 -6 37 -14z m-6759 -15 c0 -6 -4 -13 -10 -16
                -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m373 -54 c18 -58 23 -142
                8 -151 -11 -7 -27 57 -37 142 -8 77 6 82 29 9z m-552 -18 c-9 -23 -10 -23 -10
                -2 -1 12 2 30 6 40 6 15 8 15 11 2 1 -8 -2 -26 -7 -40z m159 -19 c0 -13 -1
                -13 -10 0 -5 8 -10 22 -10 30 0 13 1 13 10 0 5 -8 10 -22 10 -30z m203 21 c5
                -22 -31 -111 -46 -111 -11 0 -2 67 15 105 13 30 24 32 31 6z m5762 -61 l5 -35
                -14 30 c-18 35 -21 64 -6 49 5 -5 12 -25 15 -44z m224 14 c37 -15 120 -74 121
                -85 0 -11 -149 30 -191 52 -95 48 -36 76 70 33z m-716 -48 c6 -15 -1 -26 -15
                -26 -4 0 -8 9 -8 20 0 23 15 27 23 6z m-4003 -17 c0 -6 7 -9 15 -5 23 9 91
                -58 120 -119 51 -104 33 -179 -47 -191 -47 -8 -51 -21 -52 -156 -1 -53 -4 -96
                -7 -95 -4 1 -7 -2 -7 -8 0 -5 4 -9 8 -8 11 3 15 -9 39 -132 24 -121 30 -247
                12 -253 -20 -7 -62 39 -86 93 -63 142 -105 490 -87 722 10 124 26 163 67 163
                14 0 25 -5 25 -11z m4530 -168 c0 -23 -32 -115 -65 -190 -29 -65 -81 -113 -96
                -90 -24 39 -2 126 51 204 64 93 110 125 110 76z m775 19 c3 -5 2 -10 -4 -10
                -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-728 -97 c-2 -16 -4 -5
                -4 22 0 28 2 40 4 28 2 -13 2 -35 0 -50z m-5535 5 c-9 -9 -12 -7 -12 12 0 19
                3 21 12 12 9 -9 9 -15 0 -24z m5896 -118 c44 -16 38 -38 -15 -68 -49 -27 -58
                -24 -78 24 -12 29 -19 34 -48 34 -71 0 -156 13 -150 23 8 13 248 2 291 -13z
                m-288 -85 c0 -9 -9 -15 -21 -15 -18 0 -19 2 -9 15 7 8 16 15 21 15 5 0 9 -7 9
                -15z m-358 -10 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
                11 -8 11 -13z m286 2 c7 -8 6 -18 -3 -31 -16 -21 -35 -14 -35 13 0 31 20 40
                38 18z m-6498 -100 c0 -18 -2 -19 -10 -7 -13 20 -13 43 0 35 6 -3 10 -16 10
                -28z m1076 -27 c66 -43 210 -166 259 -221 23 -26 24 -29 5 -13 -37 33 -122 84
                -137 84 -29 0 -223 159 -223 182 0 18 39 5 96 -32z m5134 30 c0 -5 -2 -10 -4
                -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m280 -18 c0 -14 -30
                -37 -92 -72 -52 -29 -60 -30 -85 -18 -15 7 -28 18 -27 23 3 33 204 98 204 67z
                m-415 -52 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
                -10z m-6205 -96 c0 -6 -65 -34 -81 -34 -19 0 10 28 34 33 36 7 47 7 47 1z
                m-455 -184 c0 -9 -34 -32 -83 -57 -70 -34 -89 -49 -118 -92 -46 -67 -135 -154
                -180 -176 -22 -10 -64 -18 -103 -19 -57 -1 -78 4 -151 38 -133 60 -233 94
                -299 102 -55 6 -61 9 -61 30 0 27 -30 50 -84 65 -22 6 -35 14 -30 19 25 25
                638 90 974 104 119 4 135 3 135 -14z m2105 -39 c19 -16 32 -33 28 -36 -4 -4
                -28 14 -55 40 -50 49 -33 46 27 -4z m5277 29 c8 -12 9 -21 3 -25 -15 -9 -30 4
                -30 26 0 24 12 24 27 -1z m-8468 -134 c17 -20 10 -77 -10 -89 -12 -7 -26 -7
                -47 2 -28 12 -29 15 -21 44 14 50 54 72 78 43z m8480 -2 c39 -8 41 -24 3 -24
                -31 0 -81 29 -51 30 9 0 31 -3 48 -6z m-8424 -103 c3 -5 1 -12 -5 -16 -5 -3
                -10 1 -10 9 0 18 6 21 15 7z m7984 -5 c-2 -2 -18 -7 -34 -10 l-30 -6 30 14
                c26 12 46 14 34 2z m-154 -46 c-28 -12 -45 -12 -45 0 0 6 15 10 33 9 27 0 29
                -2 12 -9z m1393 -22 c2 -9 -10 -18 -35 -26 -20 -6 -39 -9 -41 -7 -9 9 9 36 30
                45 29 11 42 8 46 -12z m-1498 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
                5 10 10 10 6 0 10 -4 10 -10z m-46 -9 c6 -8 -43 -35 -51 -28 -5 6 26 37 37 37
                5 0 11 -4 14 -9z m-3962 -36 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5
                15 11 13 6 -2 11 -8 11 -13z m-201 -50 c10 -11 15 -22 13 -24 -6 -6 -74 61
                -74 73 0 10 34 -17 61 -49z m5409 46 c0 -12 -138 -71 -166 -71 -34 0 -37 15
                -6 30 20 9 125 40 170 49 1 1 2 -3 2 -8z m-5054 -87 c-11 -7 -22 -12 -25 -9
                -2 3 4 12 15 19 10 8 21 12 24 9 3 -2 -4 -11 -14 -19z m-2537 -23 c11 -7 9
                -10 -7 -15 -32 -11 -72 -46 -72 -64 0 -9 25 -40 56 -68 51 -46 55 -53 45 -72
                -14 -26 -61 -52 -95 -52 -34 0 -108 30 -179 73 -70 43 -162 61 -256 51 -36 -3
                -140 -24 -231 -45 -251 -58 -385 -67 -412 -27 -5 7 -6 25 -4 41 3 15 3 27 0
                27 -2 0 -26 -11 -52 -24 -71 -36 -173 -76 -166 -65 3 5 36 25 72 43 233 116
                502 182 812 199 133 8 476 6 489 -2z m1741 -20 c27 -19 58 -66 49 -75 -6 -6
                -79 18 -130 44 -39 20 -50 44 -26 53 21 9 81 -4 107 -22z m5590 20 c0 -12 -20
                -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m-5060 -32 c0 -6 -4 -7 -10 -4 -5
                3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m5640 -12 c0 -13 -15 -47
                -33 -77 -32 -54 -33 -55 -85 -58 -47 -3 -52 -1 -52 18 0 30 27 67 78 106 54
                41 92 45 92 11z m-5093 -73 c4 -10 -9 -31 -38 -60 l-45 -44 -39 13 c-48 17
                -59 49 -23 69 68 38 135 49 145 22z m-422 -4 c3 -5 -1 -10 -9 -10 -9 0 -16 5
                -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-1008 -6 c-3 -3 -12 -4 -19 -1 -8 3
                -5 6 6 6 11 1 17 -2 13 -5z m44 -52 c-9 -25 -9 -24 -10 18 0 36 2 41 10 27 7
                -12 7 -27 0 -45z m647 24 c42 -22 112 -91 112 -111 0 -26 -35 -16 -70 20 -26
                27 -37 32 -47 23 -17 -14 -129 -4 -177 16 -41 17 -46 38 -13 61 29 21 147 16
                195 -9z m-1793 -5 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
                m1425 -16 c22 -23 -6 -19 -50 6 l-35 20 35 -6 c19 -3 41 -12 50 -20z m-1276
                -97 c6 -22 5 -28 -7 -28 -26 0 -147 -40 -147 -49 0 -5 24 -19 53 -31 47 -21
                62 -22 158 -17 72 4 109 3 117 -5 6 -6 48 -12 94 -13 65 -2 105 -10 183 -37
                128 -45 168 -52 210 -38 31 10 60 51 77 109 5 17 109 -210 132 -288 9 -30 16
                -65 16 -78 0 -32 -34 -63 -70 -63 -26 0 -30 -3 -30 -29 0 -44 -20 -54 -61 -31
                -140 82 -201 90 -270 38 -41 -32 -53 -46 -63 -80 -2 -9 -36 -33 -74 -53 -38
                -20 -82 -50 -98 -66 -26 -27 -29 -37 -27 -90 l1 -59 -37 0 c-97 0 -240 59
                -307 126 -41 40 -43 41 -48 19 -9 -35 -56 -86 -94 -102 -30 -12 -37 -12 -58 2
                -20 13 -24 24 -24 63 0 27 7 71 15 100 12 38 13 61 6 93 -6 27 -6 49 0 60 15
                28 12 44 -6 29 -12 -10 -20 -8 -41 9 -34 26 -48 26 -164 -4 -116 -30 -123 -30
                -115 -6 8 26 -16 42 -76 50 -29 3 -54 11 -56 18 -3 7 16 35 42 62 l47 50 66
                -6 c87 -7 97 3 89 87 -6 73 9 97 68 110 22 5 41 7 43 5 2 -2 -1 -20 -8 -41
                -17 -50 0 -106 43 -138 101 -76 242 -50 315 59 31 45 32 51 16 60 -55 32 -285
                188 -290 197 -4 6 7 24 25 40 38 34 77 36 161 8 54 -18 64 -19 94 -6 18 7 44
                29 57 47 l24 34 6 -45 c3 -25 9 -57 13 -72z m-1014 72 c0 -5 -4 -10 -9 -10 -5
                0 -18 -3 -28 -6 -16 -5 -16 -4 1 10 23 18 36 20 36 6z m118 -12 c-5 -32 -28
                -35 -28 -4 0 17 5 26 16 26 10 0 14 -7 12 -22z m2200 -38 c107 -27 216 -53
                243 -56 43 -6 55 -13 89 -52 45 -51 50 -67 18 -58 -13 4 -61 10 -109 13 -91 6
                -125 -3 -163 -46 -23 -26 -57 -27 -85 -3 -11 9 -42 48 -68 87 -35 50 -60 75
                -93 92 -25 13 -58 34 -75 48 l-30 24 40 0 c22 1 127 -22 233 -49z m-53 40 c3
                -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m2644
                -51 c19 -85 9 -356 -11 -324 -4 6 -11 78 -17 160 -14 202 1 291 28 164z
                m-5534 32 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m875 -29 c0
                -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6 -3 10 -14 10 -23z m2624 -14 c28 -39
                17 -43 -19 -8 -19 19 -35 38 -35 42 0 14 38 -10 54 -34z m-3312 -13 c0 -5 -5
                -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m172 5 c28
                -10 35 -30 12 -30 -17 0 -66 39 -49 40 6 0 23 -4 37 -10z m3042 -105 c42 -87
                43 -90 21 -93 -14 -3 -29 5 -44 22 -25 29 -53 114 -53 159 0 26 2 28 17 16 9
                -8 36 -54 59 -104z m-3461 95 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7
                10 16 10 8 0 12 -4 9 -10z m1871 1 c-3 -5 -16 -11 -28 -14 -22 -6 -22 -5 -4 8
                22 17 40 20 32 6z m1759 -15 c12 -9 16 -19 11 -32 -7 -18 -9 -18 -27 5 -31 38
                -22 55 16 27z m-2790 -36 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10
                16 10 6 0 7 -4 4 -10z m-345 -30 c0 -12 -39 -19 -46 -9 -3 6 0 16 6 25 10 11
                16 12 26 3 8 -6 14 -15 14 -19z m2065 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
                10 0 6 4 10 9 10 6 0 13 -4 16 -10z m1195 -13 c70 20 85 11 85 -53 0 -41 3
                -49 18 -47 13 1 25 -12 38 -40 22 -50 24 -102 4 -137 -31 -54 -16 -102 60
                -191 46 -54 58 -89 29 -89 -36 0 -124 129 -222 325 -64 128 -102 220 -102 242
                1 16 3 15 19 -4 18 -21 19 -21 71 -6z m312 -63 c29 -86 33 -123 13 -130 -31
                -12 -58 29 -63 95 -8 104 20 123 50 35z m5076 34 c14 -14 16 -28 2 -28 -5 0
                -14 9 -20 20 -12 22 -1 27 18 8z m125 -13 c1 -5 -6 -11 -15 -13 -11 -2 -18 3
                -18 13 0 17 30 18 33 0z m-8172 -23 c-14 -26 -59 -62 -77 -62 -29 0 -12 32 33
                60 51 32 60 33 44 2z m7754 18 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10
                4 10 3 0 8 -4 11 -10z m2140 0 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11
                10 16 10 6 0 7 -4 4 -10z m-10750 -20 c-10 -11 -25 -20 -34 -20 -12 0 -9 6 9
                20 33 26 48 26 25 0z m9048 -43 c-3 -6 -11 -3 -19 8 -7 10 -11 22 -7 28 3 6
                11 3 19 -8 7 -10 11 -22 7 -28z m-6693 7 c0 -8 -4 -14 -9 -14 -11 0 -22 26
                -14 34 9 9 23 -3 23 -20z m-1181 -19 c26 -13 41 -33 41 -56 0 -13 -90 -11
                -129 2 -39 14 -41 44 -3 57 39 15 59 14 91 -3z m9205 -6 c-3 -5 -10 -7 -15 -3
                -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-6644 -5 c0 -8 -5 -12 -10
                -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m5598 -19 c54 -29 57 -32 43 -49 -8 -9
                -20 -16 -29 -16 -22 0 -62 40 -62 62 0 22 12 23 48 3z m-105 -10 c1 -5 -6 -11
                -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z m-8838 -35 c-5 -8 -11 -8 -17
                -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m2137 -12 c-9 -9 -12 -7
                -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m-2299 -10 c-13 -12 -26 -18 -30
                -14 -4 4 1 14 11 22 31 22 43 17 19 -8z m8997 1 c0 -12 -50 -32 -59 -23 -8 8
                39 45 50 38 5 -3 9 -10 9 -15z m210 -29 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
                -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-6684 -27 c-10 -10 -19 5 -10 18 6 11 8
                11 12 0 2 -7 1 -15 -2 -18z m-2262 -32 c7 -11 -22 -23 -35 -15 -5 3 -9 9 -9
                15 0 12 37 12 44 0z m2056 -26 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5
                15 10 15 6 0 10 -7 10 -15z m6595 5 c3 -5 3 -15 -1 -21 -6 -11 -23 6 -24 24 0
                10 18 9 25 -3z m-6649 -77 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21
                -2 -24z m-76 -47 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
                10 -2 10 -4z m8694 -27 c14 -11 24 -25 21 -30 -10 -14 -23 -11 -45 13 -37 40
                -21 52 24 17z m-8529 1 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16
                10 8 0 12 -4 9 -10z m215 -10 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13
                10 21 10 8 0 14 -4 14 -10z m-465 -57 c-7 -16 -9 -15 -15 7 -9 35 0 61 13 32
                5 -11 6 -29 2 -39z m365 18 c13 -25 13 -34 0 -26 -16 10 -32 45 -21 45 6 0 15
                -9 21 -19z m-311 -67 c-8 -20 -13 -24 -16 -13 -2 8 2 29 8 45 8 20 13 24 16
                13 2 -8 -2 -29 -8 -45z m76 46 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6
                10 14 10 8 0 18 -4 21 -10z m1304 -137 c1 -57 -10 -85 -27 -68 -10 9 -7 137 3
                161 7 18 8 17 15 -6 5 -14 8 -53 9 -87z m-1119 87 c0 -5 -2 -10 -4 -10 -3 0
                -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-645 -30 c-19 -21 -50 -26
                -60 -10 -11 18 6 30 42 30 35 0 36 0 18 -20z m475 -23 c0 -16 -18 3 -23 25 -6
                22 -5 22 8 4 8 -11 15 -24 15 -29z m-392 21 c-3 -7 -13 -15 -24 -17 -16 -3
                -17 -1 -5 13 16 19 34 21 29 4z m-868 -178 c14 -10 22 -21 19 -24 -2 -3 -18 6
                -34 19 -35 29 -22 33 15 5z m2540 -15 c0 -37 -18 -85 -32 -85 -11 0 -10 23 3
                76 14 52 29 58 29 9z m-47 -160 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7
                -12 3 -20z m-877 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
                m-1836 -3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
                10 -10z m2754 -153 c-1 -28 -9 -79 -19 -112 -16 -56 -18 -58 -27 -35 -17 40
                -16 194 1 214 24 29 46 -3 45 -67z m7219 26 c-7 -2 -21 -2 -30 0 -10 3 -4 5
                12 5 17 0 24 -2 18 -5z m-179 -23 c-12 -12 -248 -71 -394 -99 -121 -23 -370
                -55 -376 -48 -13 13 371 99 584 131 187 28 199 29 186 16z m306 -50 c0 -5 -2
                -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-1090 -19 c0
                -5 -7 -11 -15 -15 -9 -3 -15 0 -15 9 0 8 7 15 15 15 8 0 15 -4 15 -9z m978 4
                c-12 -13 -627 -125 -765 -141 -133 -14 -50 15 182 65 183 39 193 41 380 61
                184 20 210 22 203 15z m-219 -125 c-75 -15 -165 -36 -200 -45 -102 -28 -157
                -36 -202 -33 l-42 4 38 17 c65 30 156 49 322 67 223 25 241 22 84 -10z m-7184
                1 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-65 -41 c-24
                -15 -37 -11 -16 5 11 8 22 12 25 9 3 -3 -1 -9 -9 -14z m7521 2 c-7 -2 -18 1
                -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m-124 -19 c-12 -11 -350 -70 -376
                -65 -22 5 -23 6 -7 17 30 23 403 69 383 48z m-7327 -48 c-60 -64 -274 -189
                -395 -230 -57 -20 -205 -31 -559 -41 -164 -5 -237 -4 -233 3 11 17 51 22 257
                33 259 13 373 26 489 55 117 30 151 44 266 114 99 59 187 103 194 96 2 -3 -6
                -16 -19 -30z m6634 5 c-56 -19 -192 -45 -200 -37 -7 6 193 56 226 56 17 0 9
                -6 -26 -19z m776 -20 c13 -8 13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z
                m-596 -9 c3 -5 0 -14 -7 -19 -17 -14 -187 -52 -229 -52 l-33 1 28 15 c24 13
                120 45 172 57 35 8 64 7 69 -2z m466 -10 c0 -18 -341 -88 -570 -116 -108 -14
                -10 29 139 60 62 13 195 33 386 58 17 2 33 5 38 6 4 0 7 -3 7 -8z m217 -57
                c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-247 -33 c0 -11 -124
                -37 -295 -61 -165 -23 -195 -24 -140 -4 42 16 225 49 347 63 40 5 76 9 81 10
                4 0 7 -3 7 -8z m230 -61 c0 -5 -16 -10 -35 -10 -19 0 -35 5 -35 10 0 6 16 10
                35 10 19 0 35 -4 35 -10z m-9065 -20 c17 -18 16 -19 -8 -25 -32 -8 -47 0 -47
                26 0 25 31 25 55 -1z m8805 -35 c0 -8 -4 -17 -10 -20 -6 -4 -10 5 -10 20 0 15
                4 24 10 20 6 -3 10 -12 10 -20z m-9430 -9 c0 -11 -19 -15 -25 -6 -3 5 1 10 9
                10 9 0 16 -2 16 -4z m142 -11 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5
                15 11 13 6 -2 11 -8 11 -13z m728 5 c21 -7 17 -8 -20 -9 -25 0 -52 4 -60 9
                -19 12 42 12 80 0z m-391 -21 c41 -5 76 -11 79 -13 8 -8 -190 -18 -235 -11
                -69 10 -93 16 -93 25 0 21 87 20 249 -1z m221 -35 c20 -10 21 -13 7 -14 -19 0
                -77 27 -77 36 0 7 38 -5 70 -22z m-555 -44 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16
                10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-35 -90 c0 -5 -2 -10 -4 -10 -3 0 -8 5
                -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m235 -10 c3 -5 1 -10 -4 -10 -6 0
                -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-10 -90 c8 -13 -5 -13 -25 0
                -13 8 -13 10 2 10 9 0 20 -4 23 -10z m3380 -281 c11 -17 -1 -21 -15 -4 -8 9
                -8 15 -2 15 6 0 14 -5 17 -11z m1758 -663 c3 -8 -1 -20 -9 -26 -12 -10 -14 -8
                -14 14 0 28 14 35 23 12z m87 -116 c127 -26 243 -63 213 -68 -23 -3 -265 47
                -343 72 l-45 14 38 1 c22 1 83 -8 137 -19z m1367 -156 c-3 -3 -12 -4 -19 -1
                -8 3 -5 6 6 6 11 1 17 -2 13 -5z m302 -6 c28 -6 51 -15 51 -20 0 -11 -181 -10
                -199 1 -45 29 53 41 148 19z"/>
                <path d="M8310 9854 c0 -14 4 -23 10 -19 6 3 10 15 10 26 0 10 -4 19 -10 19
                -5 0 -10 -12 -10 -26z"/>
                <path d="M4130 5461 c0 -10 61 -32 69 -24 8 6 -14 22 -41 28 -18 5 -28 3 -28
                -4z"/>
                <path d="M4204 5371 c7 -11 173 -81 192 -81 27 0 14 20 -26 40 -35 18 -140 50
                -163 50 -4 0 -6 -4 -3 -9z"/>
                <path d="M4120 4746 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
                -10 -1 -10 -9z"/>
                <path d="M4320 4590 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
                -9 -9 -20z"/>
                <path d="M8935 4680 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
                -12 6 -17 -2z"/>
                <path d="M1665 3570 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
                0 -12 -4 -9 -10z"/>
                <path d="M1910 3556 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
                3 -15 4 -15 2z"/>
                <path d="M2185 3530 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z"/>
                <path d="M1393 3515 c-3 -9 -3 -19 0 -22 17 -16 177 -10 177 7 0 28 -166 41
                -177 15z"/>
                <path d="M2555 3331 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                <path d="M2732 3148 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
                -10z"/>
                <path d="M2346 2988 c-22 -29 -24 -35 -11 -48 13 -13 17 -12 34 5 11 11 23 32
                26 48 10 39 -17 36 -49 -5z"/>
                <path d="M3498 2873 c-10 -2 -18 -9 -18 -14 0 -15 43 -7 48 9 4 12 0 13 -30 5z"/>
                <path d="M2446 2829 c-21 -30 -20 -39 3 -39 22 0 35 25 22 47 -7 13 -11 12
                -25 -8z"/>
                <path d="M2526 2701 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
                <path d="M4300 3325 c0 -2 10 -10 23 -16 20 -11 21 -11 8 4 -13 16 -31 23 -31
                12z"/>
                <path d="M7997 12512 c3 -12 9 -25 14 -28 14 -8 11 10 -6 32 -13 18 -14 18 -8
                -4z"/>
                <path d="M10770 8321 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
                0 -10 -4 -10 -9z"/>
                <path d="M2771 8304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                <path d="M2794 8144 c28 -78 37 -97 42 -91 9 9 -25 104 -42 117 -12 11 -12 7
                0 -26z"/>
                <path d="M4882 8075 c-6 -14 -7 -25 -2 -25 9 0 25 39 18 46 -3 2 -10 -7 -16
                -21z"/>
                <path d="M2832 7958 c-6 -40 -34 -145 -63 -233 -33 -104 -52 -184 -55 -230 -5
                -65 -3 -74 26 -123 55 -94 90 -63 160 143 67 195 76 187 72 -63 -5 -310 36
                -442 196 -630 71 -83 382 -337 382 -312 0 5 7 10 16 10 10 0 13 -6 9 -19 -4
                -13 2 -23 19 -35 25 -16 25 -16 35 22 6 20 11 42 11 49 0 6 3 14 8 16 4 3 8
                -17 9 -45 3 -56 39 -131 65 -136 56 -11 303 222 430 403 42 61 134 200 205
                310 71 110 152 232 181 270 156 212 208 312 238 458 19 88 6 157 -29 157 -6 0
                -34 -24 -62 -53 -48 -51 -55 -55 -165 -87 -141 -40 -208 -69 -239 -104 -14
                -14 -32 -26 -42 -26 -9 0 -20 -7 -23 -16 -4 -9 -13 -13 -24 -10 -10 4 -61 -3
                -113 -14 -158 -33 -281 -43 -448 -37 -265 9 -440 63 -600 183 -62 47 -140 135
                -172 196 l-15 29 -12 -73z m1161 -498 c14 -12 24 -27 21 -34 -3 -7 6 -42 20
                -76 14 -35 24 -65 23 -67 -10 -9 -117 163 -117 187 0 16 25 12 53 -10z m507
                -24 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10
                -14z m-418 -53 c65 -68 126 -149 154 -206 57 -111 -9 -52 -124 113 -51 72 -92
                133 -92 135 0 14 21 0 62 -42z m-495 -45 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
                -3 4 -12 1 -19z m418 -68 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
                3 0 8 -4 11 -10z m-655 -10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10
                9 10 9 0 16 -4 16 -10z m240 -705 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8
                15 10 15 2 0 4 -7 4 -15z"/>
                <path d="M4840 8009 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
                -5 -10 -11z"/>
                <path d="M10864 7995 c-11 -29 13 -130 51 -218 20 -45 41 -99 47 -119 18 -65
                39 -41 53 59 8 60 -1 113 -19 113 -7 0 -27 32 -46 70 -19 39 -44 80 -57 92
                -21 19 -23 20 -29 3z"/>
                <path d="M8420 7711 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
                -10 -4 -10 -9z"/>
                <path d="M4779 7448 c-63 -81 -171 -240 -265 -390 -16 -26 -71 -101 -122 -168
                -86 -111 -191 -284 -179 -296 7 -8 96 67 170 143 176 180 437 593 485 766 18
                63 -11 45 -89 -55z"/>
                <path d="M10386 7458 c18 -60 75 -166 103 -192 17 -17 31 -26 31 -20 0 26
                -121 234 -136 234 -3 0 -2 -10 2 -22z"/>
                <path d="M10245 7414 c-33 -19 -49 -32 -35 -28 27 7 110 52 110 59 0 10 -18 3
                -75 -31z"/>
                <path d="M2725 7211 c24 -102 105 -291 125 -291 15 0 12 22 -10 64 -10 22 -31
                75 -46 120 -36 109 -61 166 -73 166 -5 0 -3 -25 4 -59z"/>
                <path d="M10676 7161 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
                <path d="M10442 7077 c-77 -20 -82 -22 -55 -30 41 -14 59 -32 82 -88 12 -27
                26 -49 31 -49 4 0 14 17 21 38 6 20 23 56 36 80 45 76 24 85 -115 49z"/>
                <path d="M2821 6865 c-17 -15 -39 -50 -50 -81 -27 -70 -26 -72 5 -41 32 32 71
                41 122 28 44 -12 120 -75 151 -124 45 -73 46 -174 2 -221 -27 -29 -27 -31 10
                -31 68 0 63 -62 -17 -219 -25 -48 -42 -91 -39 -95 14 -13 191 -4 253 13 35 9
                116 40 180 68 65 28 152 66 196 84 l78 32 -33 17 c-45 23 -165 104 -259 175
                -41 31 -100 70 -130 86 -34 18 -109 81 -195 164 -129 125 -189 170 -228 170
                -9 0 -29 -11 -46 -25z m329 -415 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
                5 10 10 10 6 0 10 -4 10 -10z m310 -165 c0 -16 -31 -63 -45 -68 -20 -8 -19 1
                5 41 17 28 40 43 40 27z m-65 -94 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18
                6 21 15 7z"/>
                <path d="M1082 6692 c-9 -16 -10 -27 -3 -36 7 -9 5 -17 -9 -29 -17 -14 -20
                -30 -20 -94 0 -43 4 -84 9 -91 11 -17 44 179 39 233 -3 39 -3 39 -16 17z"/>
                <path d="M9308 6621 c-31 -10 -76 -29 -100 -43 -44 -24 -45 -24 -143 -11 -173
                22 -233 -1 -162 -62 82 -71 401 -198 450 -179 8 3 29 32 47 65 17 32 35 59 40
                59 5 0 12 -12 16 -27 15 -70 12 -69 71 -12 30 30 61 66 69 81 32 62 5 118 -67
                138 -52 15 -158 11 -221 -9z m-130 -140 c4 -11 -2 -12 -24 -7 -45 11 -55 28
                -15 24 19 -2 37 -9 39 -17z m222 -61 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
                6 -1 10 4 10 6 0 11 -4 11 -10z"/>
                <path d="M9753 6523 c-13 -20 -19 -53 -21 -130 -4 -91 -2 -103 12 -103 30 0
                77 30 86 56 24 63 2 171 -39 193 -17 10 -23 7 -38 -16z"/>
                <path d="M1012 6430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
                <path d="M2960 6365 c-14 -8 -20 -14 -15 -14 6 0 21 6 35 14 14 8 21 14 15 14
                -5 0 -21 -6 -35 -14z"/>
                <path d="M1015 6310 c-8 -16 -18 -51 -21 -77 -3 -27 -7 -61 -9 -78 -4 -22 -2
                -26 5 -15 8 13 10 13 10 -2 0 -9 -4 -19 -9 -22 -4 -3 -11 -20 -15 -38 -3 -17
                -13 -40 -22 -49 -12 -13 -29 -128 -70 -466 -71 -596 -138 -1092 -180 -1347
                -24 -142 -32 -212 -25 -219 7 -7 16 4 29 34 24 55 44 137 37 148 -2 5 4 51 15
                102 11 52 40 252 65 444 24 193 60 430 80 528 39 198 70 432 58 450 -4 7 -8
                57 -9 111 -1 57 1 93 6 86 10 -16 8 -23 49 180 38 186 47 260 32 260 -6 0 -17
                -13 -26 -30z"/>
                <path d="M2852 6234 c-25 -17 -29 -46 -8 -63 11 -9 18 -6 35 15 36 45 17 79
                -27 48z"/>
                <path d="M4692 6199 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
                -15 -5 -13 -11z"/>
                <path d="M2602 6100 c2 -8 16 -23 31 -34 21 -16 30 -17 39 -8 16 16 -5 38 -46
                48 -22 5 -28 4 -24 -6z"/>
                <path d="M4845 6101 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                <path d="M10090 5810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
                0 -10 -4 -10 -10z"/>
                <path d="M10101 5789 c-22 -20 -23 -28 -19 -108 4 -77 10 -97 56 -200 29 -62
                78 -150 111 -195 32 -44 68 -100 80 -123 25 -49 39 -60 47 -38 10 26 -11 111
                -86 340 -37 116 -71 221 -74 235 -5 25 -71 110 -85 110 -4 0 -18 -10 -30 -21z
                m20 -111 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z"/>
                <path d="M10035 5730 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
                -2 0 -7 -4 -10 -10z"/>
                <path d="M9980 5596 c0 -14 5 -28 10 -31 6 -3 10 5 10 19 0 14 -4 28 -10 31
                -6 3 -10 -5 -10 -19z"/>
                <path d="M10010 5523 c0 -10 5 -25 10 -33 8 -12 10 -9 10 12 0 15 -4 30 -10
                33 -5 3 -10 -2 -10 -12z"/>
                <path d="M10040 5416 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
                -10 -1 -10 -9z"/>
                <path d="M10095 5290 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
                -2 0 -7 -4 -10 -10z"/>
                <path d="M874 5025 c-3 -14 -4 -28 -1 -31 10 -10 27 7 27 26 0 30 -19 33 -26
                5z"/>
                <path d="M881 4954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                <path d="M870 4880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
                -10 -4 -10 -10z"/>
                <path d="M858 4785 c-2 -19 0 -41 4 -48 4 -7 8 9 8 36 0 55 -6 62 -12 12z"/>
                <path d="M855 4690 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
                -8 -4 -11 -10z"/>
                <path d="M846 4651 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
                <path d="M831 4571 c-6 -10 -13 -38 -16 -62 l-6 -44 19 35 c24 44 36 90 23 90
                -6 0 -15 -8 -20 -19z"/>
                <path d="M9460 4440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
                -10 -4 -10 -10z"/>
                <path d="M10910 4411 c0 -13 32 -47 63 -66 38 -24 34 -3 -8 40 -35 34 -55 44
                -55 26z"/>
                <path d="M801 4364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
                <path d="M10700 4336 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
                <path d="M11033 4314 c-3 -10 11 -52 38 -105 40 -83 59 -107 59 -76 0 24 21
                36 47 26 22 -8 23 -7 12 12 -9 17 -8 20 2 17 8 -3 16 -14 19 -27 3 -12 21 -32
                40 -44 33 -20 32 -18 -19 42 -52 59 -145 146 -177 164 -9 5 -16 2 -21 -9z"/>
                <path d="M11216 4091 c-3 -5 1 -11 9 -15 15 -6 29 7 18 18 -9 9 -21 8 -27 -3z"/>
                <path d="M10010 4064 c-150 -22 -172 -28 -150 -36 38 -14 405 15 416 33 5 9
                -213 10 -266 3z"/>
                <path d="M641 3883 c-7 -42 -17 -86 -21 -97 -8 -19 -9 -18 -10 9 -1 24 -6 16
                -21 -35 -11 -36 -18 -81 -16 -100 6 -44 -21 -160 -37 -157 -7 1 -12 -17 -12
                -48 -1 -27 -5 -71 -9 -97 -8 -50 0 -102 14 -94 5 3 14 36 21 73 7 38 23 102
                35 143 35 115 38 135 20 150 -18 15 -19 30 -3 82 8 27 14 35 20 26 12 -18 46
                24 54 68 7 38 -3 141 -15 148 -4 3 -13 -29 -20 -71z m-86 -402 c3 -5 1 -12 -5
                -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z"/>
                <path d="M10787 3744 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
                <path d="M10634 3681 c-23 -10 -36 -34 -28 -54 3 -10 23 -11 82 -6 109 10 137
                25 93 48 -27 15 -122 22 -147 12z"/>
                <path d="M10495 3641 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                <path d="M10395 3567 c-21 -18 -25 -26 -14 -27 22 0 52 25 47 40 -3 9 -13 5
                -33 -13z"/>
                <path d="M10620 3574 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
                -10 -7 -10 -16z"/>
                <path d="M10730 3552 c0 -5 9 -17 20 -27 22 -20 26 -9 6 18 -14 18 -26 22 -26
                9z"/>
                <path d="M10690 3500 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
                0 -15 -4 -15 -10z"/>
                <path d="M10958 3493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
                <path d="M10557 3458 c-7 -24 12 -43 26 -29 12 12 5 43 -9 48 -6 2 -14 -6 -17
                -19z"/>
                <path d="M10700 3461 c0 -14 23 -28 57 -34 45 -9 50 7 9 27 -37 18 -66 21 -66
                7z"/>
                <path d="M11925 3440 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10
                -8 0 -12 -4 -9 -10z"/>
                <path d="M11785 3421 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                <path d="M10524 3365 c-4 -8 1 -26 10 -40 12 -19 25 -25 51 -25 43 0 50 12 22
                38 -34 32 -76 45 -83 27z"/>
                <path d="M11695 3361 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
                <path d="M10790 3326 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
                -10 -1 -10 -9z"/>
                <path d="M10467 3314 c-11 -11 -8 -72 4 -80 6 -3 18 1 27 10 12 12 14 22 6 43
                -9 27 -25 39 -37 27z"/>
                <path d="M10690 3253 c-14 -2 -36 -13 -50 -23 l-25 -20 30 1 c45 1 126 23 128
                34 2 11 -42 15 -83 8z"/>
                <path d="M10526 3233 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24
                19z"/>
                <path d="M11310 2779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
                -10 -5 -10 -11z"/>
                <path d="M12000 2211 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
                0 -10 -4 -10 -9z"/>
                <path d="M8466 282 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
                <path d="M10230 75 c0 -2 10 -10 23 -16 20 -11 21 -11 8 4 -13 16 -31 23 -31
                12z"/>
                <path d="M11290 11 c-24 -7 409 -3 440 3 8 2 -82 4 -200 4 -118 0 -226 -3
                -240 -7z"/>
                <path d="M10358 3 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
                <path d="M10988 3 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
                </g>
            </svg>
    )
}

export const Heels = ({fill}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="-400 -800 2000 2300"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1023.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
            <path d="M5470 10224 c-8 -4 -24 -14 -35 -23 -11 -10 -53 -37 -93 -60 -238
            -138 -483 -398 -737 -781 -248 -375 -438 -734 -881 -1670 -182 -386 -466 -956
            -569 -1145 -320 -582 -651 -1027 -1114 -1499 -228 -231 -402 -390 -701 -642
            -257 -217 -272 -231 -386 -359 -49 -55 -142 -157 -207 -228 -436 -474 -633
            -756 -704 -1002 -20 -70 -18 -493 3 -612 18 -108 47 -195 83 -254 22 -36 39
            -93 69 -240 99 -470 151 -709 156 -718 17 -27 317 -176 494 -246 180 -71 335
            -117 518 -154 262 -54 381 -65 684 -65 287 0 374 8 700 58 85 14 198 27 250
            31 224 14 373 77 507 213 127 130 212 299 273 547 57 231 76 433 85 880 5 217
            11 323 20 355 75 245 137 582 170 911 46 464 298 1120 711 1854 108 192 276
            468 357 590 l45 67 7 -99 c10 -127 -1 -1689 -15 -2113 -25 -800 -66 -1425
            -119 -1860 -10 -80 -17 -146 -16 -148 1 -1 71 -22 156 -47 172 -51 191 -51
            243 -2 32 31 34 37 41 122 145 1924 578 4201 1005 5279 58 148 65 160 106 189
            36 25 53 50 98 141 159 321 197 654 114 1026 -72 328 -276 721 -555 1071 -118
            148 -306 353 -403 441 -39 34 -82 80 -96 100 -34 49 -111 86 -189 93 -33 2
            -67 2 -75 -1z"/>
            <path d="M11683 9301 c-184 -68 -515 -343 -813 -676 -491 -549 -945 -1237
            -1727 -2620 -237 -419 -352 -598 -570 -888 -275 -364 -628 -748 -1008 -1093
            -121 -109 -446 -392 -505 -439 -21 -16 -89 -93 -151 -170 -236 -295 -380 -444
            -586 -608 -284 -226 -373 -449 -318 -804 29 -187 47 -259 101 -403 30 -79 54
            -151 54 -159 0 -36 183 -898 195 -916 15 -24 188 -115 340 -180 388 -165 834
            -265 1435 -322 255 -24 953 -24 1220 0 102 9 241 22 310 27 69 6 164 20 212
            31 249 58 447 227 578 494 127 259 186 549 196 960 5 188 10 251 40 430 86
            517 218 1114 357 1610 l42 150 7 -200 c22 -578 -23 -1499 -103 -2138 -12 -92
            -20 -169 -18 -171 2 -1 75 -22 161 -45 l157 -43 43 21 c53 25 78 65 78 124 0
            128 75 862 136 1322 199 1518 491 2833 806 3633 53 136 75 176 196 360 76 114
            161 251 190 305 l54 97 5 137 c21 538 -180 1182 -539 1726 -117 177 -311 429
            -326 425 -4 -2 -20 6 -36 17 -42 28 -145 31 -213 6z"/>
            </g>
        </svg>
    )
}
